import React from 'react';
import '../assets/css/Policy.css';
import { Helmet } from 'react-helmet';



const TermsPolicyPage = () => {
  return (
    <div className="terms-policy-container">
      <Helmet>
        <title>Terms and Policies | Lecturemeet </title>
        <meta name="description" content="Read Lecturemeet's Terms and Policies to understand the terms of use, privacy policy, and other important policies governing your use of our website and services." />
<meta name="keywords" content="Lecturemeet, Terms and Policies, terms of use, privacy policy, website, services" />
<meta name="author" content="Lecturemeet" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta charset="UTF-8" />
<meta name="robots" content="index, follow" />

<script type="application/ld+json">
    {`
        {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Lecturemeet",
        "url": "https://lecturemeet.com/terms/policy",
        "logo": "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n"
        }
    `}
</script>

<meta property="og:title" content="Terms and Policies | Lecturemeet" />
<meta property="og:description" content="Read Lecturemeet's Terms and Policies to understand the terms of use, privacy policy, and other important policies governing your use of our website and services." />
<meta property="og:image" content="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n" />
<meta property="og:url" content="https://lecturemeet.com/terms/policy" />
<meta name="twitter:card" content="summary_large_image" />

      </Helmet>
<>
      <h1>Terms and Policy</h1>

      <section className="terms-section">
        <h2>Introduction</h2>
        <p>
        Welcome to Lecturemeet! By accessing and using our services, you agree to comply with and be bound by the following terms and conditions. If you do not agree with these terms, please do not use Lecturemeet's services.

<br />
<br />
This is the general Terms of Use also referred to in this document as “Terms of Agreement” or “Terms” or the “Agreement”. 
<br />
<br />
Lecturemeet is a digital affiliate and subsidiary of Boesnap Ltd, our parent company incorporated and domiciled within the regulatory frameworks of Nigeria. The domain name lecturemeet.com is a subsidiary and digital/affiliate company of Boesnap Ltd and it is therefore owned and managed by Boesnap Ltd.
<br />
<br />
For the purpose of this Agreement the term “We” “Us”, or “Our” shall mean Lecturemeet. 
<br />
<br />
Where the term "You" “Your” "User" “student” “instructor” is been used, it shall mean a any person who have gans access or have consented to use or browse through
our platform.
<br />
<br />
Our goal as a digital learning institution is to enable learning without borders for educators (instructors) and students. We therefore provide professional educational content with certification for individual professional growth, development and training of business  teams.
We also have among our course and professional development platform a Pre-degree section for foundational crash university curriculum.

        </p>
      </section>

      <section className="terms-section">
        <h3>Use of Services</h3>
        <p>
          You agree to use our services only for lawful purposes. You are prohibited from using our services in any way that could damage, disable, overburden, or impair our website or interfere with any other party's use of our services.
        </p>

        <h3>User Accounts</h3>
        <p>
    To access certain features, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. To access courses and programmes, we have different accounts under our platform for the various learning products and categories.
  </p>

  <h3>Individual and Professional User Accounts</h3>
  <p>
    <strong>Eligibility</strong>: Individual and professional user accounts are available to users who are at least 16 years old.<br /><br />
    <strong>Account Information</strong>: You must provide accurate and complete information when creating your account. You are responsible for keeping your account information up to date.<br /><br />
    <strong>Security</strong>: You are responsible for maintaining the confidentiality of your account and restricting unauthorised access to your account. You agree to notify Lecturemeet immediately of any unauthorized use of your account or any other breach of security.<br /><br />
    <strong>Usage</strong>: Individual and professional accounts are intended for personal use. You are not to share your account or course details with others.
  </p>

  <h3>Business For Teams Accounts</h3>
  <p>
    <strong>Eligibility</strong>: Business for Teams accounts are available to organizations and businesses looking to provide educational resources to their employees.<br /><br />
    <strong>Account Information</strong>: An authorized representative must create the account and provide accurate and complete information about the organization and team members to be onboarded.<br /><br />
    <strong>Security</strong>: The organization is responsible for maintaining the confidentiality of the account credentials and for all activities that occur under the account.<br /><br />
    <strong>Usage</strong>: Business for Teams accounts are intended for organizational use. The account may be accessed by multiple users within the organization, as designated by the account administrator.<br /><br />
    <strong>Details</strong>: On creation of an account for business teams, a unique business id is created used in identifying an organisation <br /><br />
    <strong>Administration</strong>: The account administrator is responsible for managing user access and ensuring that all users or team memebers comply with Lecturemeet's terms and conditions.
  </p>

  <h3>Pre-Degree User Accounts</h3>
  <p>
    <strong>Application</strong>: Pre-degree user accounts are available to only Nigeria or anyone having completed secondary education in any West African Country<br /><br />
    <strong>Eligibility</strong>: Pre-degree user accounts are available to students who have completed secondary education and meet the entry requirements for the pre-degree programmes.<br /><br />
    <strong>Account Information</strong>: You must provide accurate and complete information when creating your account. You are responsible for keeping your account information up to date.<br /><br />
    <strong>Security</strong>: You are responsible for maintaining the confidentiality of your account details, restricting unauthorised access to your account. You agree to notify 'Us' immediately of any unauthorized use of your account or any other breach of security.<br /><br />
    <strong>Usage</strong>: Pre-degree user accounts are intended for individual use by students enrolled in pre-degree programmes. You are not to share your account details or programme of study.
  </p>
      </section>

      <section className="terms-section">
        <h2>Privacy Policy</h2>
        <h3>Information Collection</h3>
        <p>
          We collect personal information you provide to us, such as your name, email address, and payment details, when you register for an account, enroll in a course.
        </p>

        <h3>Use of Information</h3>
        <p>
          We use your information to provide and improve our services, process transactions, send notifications, and communicate with you about our offerings.
        </p>

        <h3>Information Sharing</h3>
        <p>
          Your details and information are safe with us as we do not share your personal information with third parties except as necessary to provide our services, comply with the law, or protect our rights.
        </p>

        <h3>Data Security</h3>
        <p>
          We implement security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
        </p>
        <p>
          For more details, visit our <a href="https://lecturemeet.com/terms/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> page.
        </p>
      </section>

      <section className="terms-section">
        <h2>Refund Policy</h2>
        <h3>Eligibility for Refunds</h3>
        <p>
          Refunds are available within 14 days of payment for a course if you are not satisfied with the course. To request a refund, contact our support team with your User details.
        </p>

        <h3>Processing Refunds</h3>
        <p>
          Refunds will be processed to the original payment method within 7-10 business days after approval.
        </p>
        
        <p>
          For more details, visit our <a href="https://lecturemeet.com/terms/refunds" target="_blank" rel="noopener noreferrer">Refund Policy</a> page.
        </p>
      </section>

      <section className="terms-section">
        <h2>Cookies Policy</h2>
        <h3>Use of Cookies</h3>
        <p>
          We use cookies to enhance your browsing experience, personalize content and ads, and analyze our traffic. By using our services, you consent to our use of cookies.
        </p>

        <h3>Managing Cookies</h3>
        <p>
          You can manage your cookie preferences through your browser settings. Note that disabling cookies may affect the functionality of our services.
        </p>
      </section>

      <section className="terms-section">
        <h2>User Conduct</h2>
        <h3>Prohibited Activities</h3>
        <p>
          You agree not to engage in any activities that are illegal, harmful, or interfere with the use of our services by others. This includes, but is not limited to, harassment, spamming, and distributing malware.
        </p>

        <h3>Content Standards</h3>
        <p>
          You are responsible for the content you post or share through our services. Your content must not be offensive, defamatory, or infringe on any intellectual property rights.
        </p>
      </section>

      <section className="terms-section">
        <h2>Intellectual Property</h2>
        <h3>Ownership</h3>
        <p>
          All content, including text, graphics, logos, and software, is the property of Lecturemeet or its licensors and is protected by intellectual property laws.
        </p>

        <h3>Use of Content</h3>
        <p>
          You may not reproduce, distribute, or create derivative works from our content without prior written permission from Lecturemeet.
        </p>
      </section>

      <section className="terms-section">
        <h2>Dispute Resolution</h2>
        <h3>Governing Law</h3>
        <p>
          These terms and policies are governed by and construed in accordance with the laws of Nigeria. Any disputes arising from these terms will be resolved within the regulatory frameworks of Nigeria.
        </p>

        <h3>Arbitration</h3>
        <p>
          Any disputes arising out of, use, or relating to these terms will be resolved through binding arbitration in accordance with the rules of local and international Arbitration regulations.
        </p>
      </section>

      <section className="terms-section">
        <h2>Termination</h2>
        <h3>Suspension and Termination</h3>
        <p>
          Lecturemeet reserves the right to suspend or terminate your account and access to our services at our discretion, without notice, for conduct that we believe violates these terms or is harmful to other users of our services, us, or third parties, or for any other reason.
        </p>
      </section>

      <section className="terms-section">
        <h2>Limitation of Liability</h2>
        <h3>Disclaimer of Warranties</h3>
        <p>
          Lecturemeet provides its services on an "as is" and "as available" basis. We do not guarantee that our services will be uninterrupted or error-free.
        </p>
        <p>
  Lecturemeet is committed to delivering high-quality educational content and experiences. However, we disclaim any liability for any direct, indirect, incidental, or consequential damages that may arise from the use of our services or reliance on the information provided in our courses. This includes, but is not limited to, any issues related to the recognition or acceptance of the Pre-Degree Programme by other educational institutions or employers.
</p>

<p>
  The Pre-Degree Programme offered by Lecturemeet is not a degree programme but a foundational crash course designed to provide students with basic knowledge and skills in their chosen fields. While Lecturemeet partners with academic institutions and utilizes a university curriculum learning model, we make no claims or guarantees regarding the equivalency or recognition of these courses as degree programmes by any educational institution or employer.
</p>

<p>
  Students are encouraged to verify the suitability and applicability of the Pre-Degree Programme for their individual educational and career goals. Lecturemeet does not accept responsibility for any outcomes related to the use of our services, including academic performance, career advancement, or any other personal or professional objectives.
</p>
<p>
  By enrolling in the Pre-Degree Programme or any other courses offered by Lecturemeet, you acknowledge and agree to these terms. You also agree to release Lecturemeet and its partners, affiliates, and staff from any liability associated with the use of our educational content and services.
</p>



        <h3>Limitation of Liability</h3>
        <p>
          In no event will Lecturemeet be liable for any direct, indirect, incidental, or consequential damages arising out of the use or inability to use our services.
        </p>

        
      </section>


      <section className="terms-section">

      <h3>Learner's Obligations</h3>
<p>
  As a learner at Lecturemeet, you agree to the following obligations:
</p>
<ul>
  <li><strong>Active Participation</strong>: Engage actively in your course</li>
  <li><strong>Academic Integrity</strong>: Maintain the highest standards of academic integrity. Do not engage in plagiarism, cheating, or any other form of academic dishonesty.</li>
<li><strong>Compliance with Policies</strong>: Adhere to all Lecturemeet policies, procedures, and terms of service. This includes any specific rules or guidelines set forth for your courses.</li>
</ul>
<h3>Agree to Notices and Communications</h3>
<p>
  By creating an account and enrolling in courses on Lecturemeet, you agree to receive notices and communications from us electronically. This includes, but is not limited to, updates on courses, changes to our terms of service, promotional offers, and other important information related to your use of our platform.
</p>
<p>
  Communications will be sent to the email address you provided during account creation. It is your responsibility to keep your contact information up to date. You can manage your communication preferences through your account settings at any time.
</p>
<h3>Scholarships</h3>
<p>
  Lecturemeet is committed to making education accessible to everyone. We offer a range of scholarships to support learners who demonstrate financial need, academic excellence, or a commitment to their chosen field of study.
</p>
<ul>
  <li><strong>Eligibility</strong>: Scholarship eligibility criteria vary depending on the specific scholarship. Criteria may include financial need, academic performance, and other relevant factors.</li>
  <li><strong>Application Process</strong>: To apply for a scholarship, you must complete the scholarship application form and provide any required documentation. Deadlines and application requirements will be specified on the scholarship information page.</li>
  <li><strong>Selection and Notification</strong>: Scholarship recipients will be selected based on the eligibility criteria and the quality of their application. Successful applicants will be notified via email.</li>
  <li><strong>Terms and Conditions</strong>: Scholarships are subject to specific terms and conditions, including maintaining satisfactory academic progress and adhering to Lecturemeet's policies.</li>
  <li><strong>Application</strong>: Scholarships are only for Pre-degree programs</li>
</ul>
<p>
  For more information on available scholarships and how to apply, please visit our Scholarships page or contact our support team.
</p>


      </section>
      <section className="terms-section">
      <h3>Changes to Terms</h3>
        <p>
          Lecturemeet reserves the right to modify these terms at any time. We will notify you of any changes by updating the terms on our website. Continued use of our services after changes constitutes acceptance of the new terms.
        </p>

        <h2>Contact Information</h2>
        <p>
          If you have any questions about these terms and policies, please contact us at:
        </p>
        <p>
          Email: support@lecturemeet.com<br />
          Abuja, FCT, Nigeria
        </p>
      </section>



      </>
    </div>
  );
};

export default TermsPolicyPage;