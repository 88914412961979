import React, { useState } from 'react';

const AboutFAQSection = () => {
  const faqs = [
    { question: 'About Lecturemeet?', answer: 'Lecturemeet is an online learning platform that offers a wide range of courses and programs for professionals and organizations.' },
    { question: 'Certificates upon course completion?', answer: 'Yes, Lecturemeet provides certificates of completion for all courses. Once you\'ve completed a course, you can download and print your certificate to showcase your achievement.' },
    { question: 'Can I enroll in multiple courses at the same time ?', answer: 'Yes, you can enroll in multiple courses simultaneously. Lecturemeet offers a flexible learning experience, allowing you to customize your learning path based on your interests and goals.' },
    { question: 'Can organizations enroll their teams in courses on Lecturemeet ?', answer: 'Yes, Lecturemeet offers business plans for organizations to enroll their teams and access specialized training programs.' },
    { question: 'How much does it cost to enroll in courses  ?', answer: 'Pricing for courses on Lecturemeet varies depending on the course and duration. Please visit our pricing page for more information.' },
    { question: 'Can organizations enroll multiple employees in courses on Lecturemeet?', answer: 'Yes, Lecturemeet offers business plans for organizations to enroll their teams and access specialized training programs.' },
    { question: 'What benefits do organizations receive by enrolling their teams  on Lecturemeet ?', answer: 'Organizations can benefit from improved employee skills, enhanced team performance, and access to specialized training tailored to their industry needs.' },
    { question: 'What is the duration of courses  ?', answer: 'Courses on Lecturemeet are designed to be self-paced, allowing learners to complete them at their own convenience.' },
    { question: 'Is there a time limit to complete course ?', answer: 'There is no time limit to complete courses on Lecturemeet. Learners can study at their own pace and time.' },
    { question: 'Do I receive a certificate upon completion of course ?', answer: 'Yes. When you take a course, a certificate of course completion is issued to you. Certificates issued by Lecturemeet is valid and can be used to enhance CV and other credentials for professional upgrading and more' },
    { question: 'Is Lecturemeet certificate valid ?', answer: 'Yes, Absolutely. We believe in borderless learning and professional development. Our certificate can be verified <a href="https://certificate.lecturemeet.com" style="color: #007bff; text-decoration: none;">here</a>.' },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="about-faqs">
      <div className="container">
        <h2>Frequently Asked Questions</h2>
        <div className="accordion">
          {faqs.map((faq, index) => (
            <div key={index} className={`accordion-item ${activeIndex === index ? 'active' : ''}`}>
              <div className="accordion-title" onClick={() => toggleAccordion(index)}>
                {faq.question}
                <span className="icon">{activeIndex === index ? '-' : '+'}</span>
              </div>
              <div className="accordion-content">
                <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutFAQSection;
