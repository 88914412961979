import React from 'react';
import { Helmet } from 'react-helmet';

const ComingSoon = () => {
  return (
    <>
      <Helmet>
        <title>Coming Soon | Lecturemeet</title>
        <meta name="robots" content="noindex" />
      </Helmet>

      <section className='coming-soon-container'>
        <div className="container">
          <div className="coming-soon-content">
            <h2>Coming Soon</h2>
            <h2>Launch Date: 30 November, 2024</h2>
            <p>
              We are excited to announce that our highly anticipated Pre-Degree and Professional sections are launching soon! Stay tuned for a new era of learning opportunities designed to empower you with the skills and knowledge needed to excel in your academic and professional pursuits.
            </p>

            <br />
            <br />
            <br />
            <p>Stay tuned for more updates and information on how to enroll. We can't wait to support you on your path to success!</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComingSoon;
