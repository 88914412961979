import React, { useState } from 'react';

const PricingPlansSection = ({ location }) => {
  const [teamSize, setTeamSize] = useState(1);

  // Pricing amounts in USD and NGN
  const payPerProgrammeUSD = 30;
  const businessForTeamsUSD = 45;
  const payPerProgrammeNGN = payPerProgrammeUSD * 1200; // For example, 1 USD = 500 NGN
  const businessForTeamsNGN = businessForTeamsUSD * 1250;

  const handleTeamSizeChange = (e) => {
    setTeamSize(parseInt(e.target.value));
  };

  return (
    <section className="price-plan-paycourse container-plan-price">
        <div className='chooseplan'>
        <h2>Cost</h2>
        <p>Explore our program prices and choose what's fit for you</p>
        </div>
      <div className="container">
        <div className="row">            
          <div className="col-md-6 price-pay-course">
            <h3>Pay Per Programme</h3>
            <div className="price-amount">
              {location === 'Nigeria' ? '₦' : '$'}
              {location === 'Nigeria' ? payPerProgrammeNGN.toLocaleString() : payPerProgrammeUSD}
            </div>
            <hr />
            <p>Pay Per Programme.</p>
          </div>
          <div className="col-md-6 price-plan-paycourse">
            <h3>Business for Teams</h3>
            <div className="price-amount">
              {location === 'Nigeria' ? '₦' : '$'}
              {(location === 'Nigeria' ? businessForTeamsNGN : businessForTeamsUSD) * teamSize.toLocaleString()}
            </div>
            <hr />
            <p>Great teams starts with a touch of progress upskilling. Choose this plan for your team of any size.</p>
            <label htmlFor="teamSize">Number of Teams:</label>
            <input
              id="teamSize"
              type="range"
              min="1"
              max="10"
              value={teamSize}
              onChange={handleTeamSizeChange}
            />
            <span>{teamSize}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingPlansSection;
