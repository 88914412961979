import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faComments, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import notificationSound from '../assets/sound/notification.wav'; 

const Chat = () => {
  const [showChat, setShowChat] = useState(false);
  const [userInactive, setUserInactive] = useState(false);
  const [isOpenOnTab, setIsOpenOnTab] = useState(false);
  const navigate = useNavigate();
  const audioRef = useRef(null);

  useEffect(() => {
    let timeout;

    const handleUserActivity = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setUserInactive(true);
        setIsOpenOnTab(false);
      }, 30000);
      setUserInactive(false);
      setIsOpenOnTab(true);
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);

    // Initialize the timeout when component mounts
    handleUserActivity();

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (userInactive && !isOpenOnTab) {
      setShowChat(true);
      if (audioRef.current) {
        audioRef.current.play();
      }
    }
  }, [userInactive, isOpenOnTab]);

  const handleQuestionClick = (redirectPath) => {
    setShowChat(false);
    navigate(redirectPath);
  };

  const handleCloseChat = () => {
    setShowChat(false);
  };

  return (
    <div className="chat-container">
      <FontAwesomeIcon
        icon={showChat ? faComments : faCommentDots}
        className={`chat-icon${showChat ? ' active' : ''}`}
        onClick={() => setShowChat(!showChat)}
      />
      {showChat && (
        <div className="chat-window">
          <audio ref={audioRef} src={notificationSound} />
          <div className="chat-header">
            <div className="chat-title">Chat Support</div>
            <FontAwesomeIcon
              icon={faTimes}
              className="close-icon"
              onClick={handleCloseChat}
            />
          </div>
          <div className="chat-message">
            How can we help you today? Please select an option to get around:
          </div>
          <div className="question-chat-box" onClick={() => handleQuestionClick('/Support/guides')}>
            Support
          </div>
          <div className="question-chat-box" onClick={() => handleQuestionClick('/organisations')}>
            For Teams
          </div>
          <div className="question-chat-box" onClick={() => handleQuestionClick('/Pricing')}>
            Pricing
          </div>
          <div className="question-chat-box" onClick={() => handleQuestionClick('/programme')}>
            Programmes
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
