import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Typeeffect from '../usercomponents/Typeeffect';
import Scrolltext from '../usercomponents/Scrolltext';
import WhySect from '../usercomponents/WhySect';
import Ourworkers from '../usercomponents/Ourworkers';
import OrganizationSect from '../usercomponents/OrganizationSect';
import Person from '../usercomponents/Person';
import Testimonials from '../usercomponents/Testimonials';
import Herocontaier from '../usercomponents/Herocontaier';
import Featured from '../usercomponents/Featured';
import Progarmssect from '../usercomponents/Progarmssect';
import Tolearn from '../usercomponents/Tolearn';
import Paypercourse from '../usercomponents/Paypercourse';
import PercourseOpt from '../usercomponents/PercourseOpt';
import Meta from '../components/Meta';



function Home() {
  return (
    <div className="container-home">
      <Helmet>
        <title>Lectuemeet | Learning Beyond Borders | Professional Certification</title>
        <Meta name="description" content="Lecturemeet is a professional online learning certification course platform for individual and business teams" />
<Meta name="keywords" content="Lecturemeet, professional learning, online courses, career development, upskilling, education" />
<Meta name="author" content="Lecturemeet" />
<Meta name="viewport" content="width=device-width, initial-scale=1.0" />
<Meta charset="UTF-8" />
<Meta name="robots" content="index, follow" />


        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Lecturemeet",
              "url": "https://lecturemeet.com/",
              "logo": "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n"
            }
          `}
        </script>
        
        <Meta property="og:title" content="Lectuemeet | Learning Beyond Borders | Professional Certification" />
        <Meta property="og:description" content="Lecturemeet is a professional online learning certification course platform for individual and business teams" />
        <Meta property="og:image" content="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n" />
        <Meta property="og:url" content="https://lecturemeet.com/" />
        <Meta name="twitter:card" content="summary_large_image" />
        <Meta name="twitter:site" content="@LectureMeet" />
        <Meta name="twitter:creator" content="@LectureMeet" />



        
      </Helmet>
      <section className='scrolltext'>
<Scrolltext />

</section>







      <section className="mt-8">
        <Typeeffect />
      </section>



      <section className='section-product-container products'>
        <h1 className='h1 text-center text-black'>Our Learning</h1>
        <p className='programe-course text-center text-black'>
          Get certification for completing 
        various programs, including pre-degree courses <br /> 
        and professional development programs from compliance to 
        tech courses <br />tailored for your growth and job navigation.</p>
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <Link to="/Legal-and-compliance">
            <div className="products-column">
              <h3>Legal & Compliance</h3>
              <p>This program covers essential topics in legal and compliance practices, including regulatory frameworks, risk management, and ethical considerations.</p>
              <Link to="/Legal-and-compliance" style={{ color: 'yellow' }}> Learn More  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
    <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
  </svg>
</Link>

              </div>
          </Link>
        </div>
        <div className="col-md-6">
          <Link to="/Corporate-and-banking-sector">
            <div className="products-column">
              <h3>Corporate & Finance</h3>
              <p>Learn, acquire corporate finance skills and strategic financial management expertise. Develop on corporate finance principles and strategic financial management</p>
              <Link to="/Corporate-and-banking-sector" style={{ color: 'yellow' }}> Learn More  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
    <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
  </svg>
</Link>
</div>
          </Link>
        </div>
      </div>
      <hr className="divider" />
      <div className="row">
        <div className="col-md-6">
          <Link to="/Human-resource-and-product-development-studies">
            <div className="products-column">
              <h3>Resource Management</h3>
              <p>This course delves into resource allocation, project management, and resource optimization strategies. Learn strategic resource planning, essential for effective HR management and organizational success.</p>
              <Link to="/Human-resource-and-product-development-studies" style={{ color: 'yellow' }}> Learn More  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
    <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
  </svg>
</Link>
</div>
          </Link>
        </div>
        <div className="col-md-6">
          <Link to="/Product-Management">
            <div className="products-column">
              <h3>Products Dev</h3>
              <p>Become a product manager, drive product success in the market, learn about lifecycle management and market analysis for product management roles.</p>
              <Link to="/Product-Management" style={{ color: 'yellow' }}> Learn More  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
    <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
  </svg>
</Link>
</div>
          </Link>
        </div>
      </div>
    </div>
  </section>


  <section>
<Tolearn />
</section>



<section>
<WhySect />
</section>


<section>
<Herocontaier />
</section>


<Progarmssect />


<section>
<Ourworkers />
</section>

<section>
<PercourseOpt />
</section>

<section>
<OrganizationSect />
</section>


<section>
<Person />
</section>


<section>
  <Testimonials />
</section>


<div className="review-section">
            <h2>Our Global Impact</h2>
            <div className="review">
                <div className="reviewer-info">
                    <p>Paye Epang</p>
                </div>
                <p className="review-text">"Our team's performance and collaboration have improved significantly."</p>
            </div>
            <div className="review">
                <div className="reviewer-info">
                    <p>Cheta Udo Modupe</p>
                </div>
                <p className="review-text">"Enrolled in the Human Resource Management course on Lecturemeet and it has
                  been an intensive and educating journey. It has impacted and shapped my work and career goals."</p>
            </div>
        </div>

<Featured />


<Paypercourse />



    </div>
  );
}

export default Home;
