import React from 'react';
import Andela from '../assets/images/Andela.png';
import Uip from '../assets/images/UIP-logo.png';
import GTBank_logo from '../assets/images/GTBank_logo.svg.png';
import Moove from '../assets/images/Moove.png';
import Uber from '../assets/images/Uber.png';
import bamboo from '../assets/images/bamboo.png';
import flutterwave from '../assets/images/flutterwave.png';
import identitypass from '../assets/images/identitypass.jpg';
import moneypoint from '../assets/images/moneypoint.png';
import norebase from '../assets/images/norebase.png';







const WhereOurLearnersWork = () => {
  return (
    <section className="where-our-learners-work">
      <h2>Where Our Learners Work</h2>
      <div className="images-container">
        <div className="image-wrapper">
          <img src={Andela} alt="Andela" />
        </div>
        <div className="image-wrapper">
          <img src={Uip} alt="Udochi Iheanacho Partnership (UIP)" />
        </div>
        <div className="image-wrapper">
          <img src={GTBank_logo} alt="GTBank Logo" />
        </div>
        <div className="image-wrapper">
          <img src={Moove} alt="Moove" />
        </div>
        <div className="image-wrapper">
          <img src={Uber} alt="Uber" />
        </div>
        <div className="image-wrapper">
          <img src={bamboo} alt="Bamboo" />
        </div>
        <div className="image-wrapper">
          <img src={flutterwave} alt="Flutterwave" />
        </div>
        <div className="image-wrapper">
          <img src={identitypass} alt="IdentityPass" />
        </div>
        <div className="image-wrapper">
          <img src={moneypoint} alt="MoneyPoint" />
        </div>
        <div className="image-wrapper">
          <img src={norebase} alt="NoRebase" />
        </div>
      </div>
    </section>
  );
};


export default WhereOurLearnersWork;
