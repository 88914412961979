import React, { useState } from 'react';

const AboutFAQSection = () => {
  const faqs = [
    { question: 'Who is eligible to apply for the scholarship?', answer: 'The scholarship is open to Nigerian citizens who have completed secondary school education in Nigeria' },
    { question: 'How are scholarship recipients selected?', answer: 'Recipients are selected based on academic performance, financial need, extracurricular activities, and the quality of their essay or personal statement.' },
    { question: 'Can I apply for the scholarship if I am already enrolled in a Pre-degree program ?', answer: ' No, the scholarship is only available to students who have been admitted to a Pre-degree program but have not yet commenced their studies and paid fees.' },
    { question: 'How can I donate to the Scholarship Fund ?', answer: 'You can donate online through our website or contact us for alternative donation methods.' },
    { question: 'How will my donation be used ?', answer: 'Donations to the Scholarship Fund will be used to cover tuition fees, learning materials and other educational expenses for scholarship recipients.' },
    { question: 'What documents do I need to submit with my application ?', answer: 'You will need to submit proof of admission to a Pre-degree program,  a personal statement or essay on why you need the scholarship and course of study, proof of financial need, and any other documents requested in the application form.' },
    { question: 'About Lecturemeet?', answer: 'Lecturemeet is an online learning platform that offers a wide range of courses and programs for professionals and organizations.' },
    { question: 'Certificates upon course completion?', answer: 'Yes, Lecturemeet provides certificates of completion for all courses. Once you\'ve completed a course, you can download and print your certificate to showcase your achievement.' },
    { question: 'Can I enroll in multiple courses at the same time ?', answer: 'Yes, you can enroll in multiple courses simultaneously. Lecturemeet offers a flexible learning experience, allowing you to customize your learning path based on your interests and goals.' },
    { question: 'Can organizations enroll their teams in courses on Lecturemeet ?', answer: 'Yes, Lecturemeet offers business plans for organizations to enroll their teams and access specialized training programs.' },
    { question: 'How much does it cost to enroll in courses  ?', answer: 'Pricing for courses on Lecturemeet varies depending on the course and duration. Please visit our pricing page for more information.' },
    { question: 'Can organizations enroll multiple employees in courses on Lecturemeet?', answer: 'Yes, Lecturemeet offers business plans for organizations to enroll their teams and access specialized training programs.' },
    { question: 'What benefits do organizations receive by enrolling their teams  on Lecturemeet ?', answer: 'Organizations can benefit from improved employee skills, enhanced team performance, and access to specialized training tailored to their industry needs.' },
    { question: 'What is the duration of courses  ?', answer: 'Courses on Lecturemeet are designed to be self-paced, allowing learners to complete them at their own convenience.' },
    { question: 'Is there a time limit to complete course ?', answer: 'There is no time limit to complete courses on Lecturemeet. Learners can study at their own pace and time.' },
    { question: 'Do I receive a certificate upon completion of course ?', answer: 'Yes, learners receive a certificate upon successful completion of courses on Lecturemeet, which can be used to showcase their skills and knowledge.' },
    { question: 'What payment methods do you accept?', answer: 'We accept payments via credit/debit card bank transfer, USSD and other payment methods depending on your country.' },
    { question: 'Are there any additional fees?', answer: 'There are no additional fees for enrolling in course programmes.' },
    { question: 'Can I get a refund if I am not satisfied with the course?', answer: 'Yes, we offer a 30-day money-back guarantee.Visit our pricing page for more information' },
    { question: 'Are there any discounts available?', answer: 'We occasionally offer discounts and promotions. Sign up for our newsletter to stay updated on our latest offers.' },
    { question: 'Do you offer installment payments?', answer: 'Yes, we offer installment payment options for only pre-degree programs. Please contact us for more details on this.' },
    { question: 'Is there a deadline for payment?', answer: 'Payment is required before you can access the course or choosen program. However, you can signup at any time.' },
    { question: 'Can organizations pay for multiple employees at once?', answer: 'Yes, organizations can enroll multiple employees and pay for them in a single transaction or in installments. Visit our payment page for more information.' },
    { question: 'Do you offer financial assistance for your programmes ?', answer: 'We do not offer financial assistance through an offer scholarships for selected  programmes. Please check our page for scholarship opportunities.' },
  ];
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="about-faqs">
      <div className="container">
        <h2>FAQ's</h2>
        <div className="accordion">
          {faqs.map((faq, index) => (
            <div key={index} className={`accordion-item ${activeIndex === index ? 'active' : ''}`}>
              <div className="accordion-title" onClick={() => toggleAccordion(index)}>
                {faq.question}
                <span className="icon">{activeIndex === index ? '-' : '+'}</span>
              </div>
              <div className="accordion-content">
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutFAQSection;
