import React, { useState } from 'react';

const AboutPricingsection = () => {
  const faqs = [
    { question: 'What payment methods do you accept?', answer: 'We accept payments via credit/debit card bank transfer, USSD and other payment methods depending on your country.' },
    { question: 'Are there any additional fees?', answer: 'There are no additional fees for enrolling in course programmes.' },
    { question: 'Can I get a refund if I am not satisfied with the course?', answer: 'Yes, we offer a 30-day money-back guarantee.Visit our pricing page for more information' },
    { question: 'Are there any discounts available?', answer: 'We occasionally offer discounts and promotions. Sign up for our newsletter to stay updated on our latest offers.' },
    { question: 'Do you offer installment payments?', answer: 'Yes, we offer installment payment options for only pre-degree programs. Please contact us for more details on this.' },
    { question: 'Is there a deadline for payment?', answer: 'Payment is required before you can access the course or choosen program. However, you can signup at any time.' },
    { question: 'Can organizations pay for multiple employees at once?', answer: 'Yes, organizations can enroll multiple employees and pay for them in a single transaction or in installments. Visit our payment page for more information.' },
    { question: 'Do you offer financial assistance for your programmes ?', answer: 'We do not offer financial assistance through an offer scholarships for selected  programmes. Please check our page for scholarship opportunities.' },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="about-faqs">
      <div className="container">
        <h2>FAQ's Payments & Pricing</h2>
        <div className="accordion">
          {faqs.map((faq, index) => (
            <div key={index} className={`accordion-item ${activeIndex === index ? 'active' : ''}`}>
              <div className="accordion-title" onClick={() => toggleAccordion(index)}>
                {faq.question}
                <span className="icon">{activeIndex === index ? '-' : '+'}</span>
              </div>
              <div className="accordion-content">
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutPricingsection;
