// ScrollingSection.js
import React, { useState, useEffect } from 'react';

const ScrollingSection = () => {
    const [position, setPosition] = useState('100%');

    useEffect(() => {
        const interval = setInterval(() => {
            setPosition(prevPosition => {
                return prevPosition === '-100%' ? '100%' : parseFloat(prevPosition) - 1 + '%';
            });
        }, 50); // Adjust the speed here

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="scrolling-section">
            <div className="scrolling-text" style={{ transform: `translateX(${position})` }}>
                <span>Lecturemeet Programmes</span>
                <a href="#get-started">Get Started</a>
            </div>
        </div>
    );
};

export default ScrollingSection;
