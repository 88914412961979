import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Blogupdates from '../usercomponents/Blogupdates';
import Meta from '../components/Meta';


const Blog = () => {
  const navigate = useNavigate();

  const handleOptionClick = (redirectUrl) => {
      if (redirectUrl) {
          navigate(redirectUrl);
      }
  };

  return (
    <div className="container-mx-auto-p-4">
      <Helmet>
        <title>Lecturemeet Blog and News Update </title>
        <Meta name="description" content="Stay informed with the latest news, updates, and insights on professional learning and career development." />
<Meta name="keywords" content="Stay informed with the latest news, updates, and insights on professional learning and career development." />
<Meta name="author" content="Lecturemeet" />
<Meta name="viewport" content="width=device-width, initial-scale=1.0" />
<Meta charset="UTF-8" />
<Meta name="robots" content="index, follow" />



<script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Lecturemeet",
              "url": "https://lecturemeet.com/Blog",
              "logo": "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n"
            }
          `}
        </script>
        
        <Meta property="og:title" content="Lecturemeet Blog and News Update" />
        <Meta property="og:description" content="Stay informed with the latest news, updates, and insights on professional learning and career development." />
        <Meta property="og:image" content="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n" />
        <Meta property="og:url" content="https://lecturemeet.com/Blog" />
        <Meta name="twitter:card" content="summary_large_image" />
        <Meta name="twitter:site" content="@LectureMeet" />
        <Meta name="twitter:creator" content="@LectureMeet" />
        
      </Helmet>




<section className="what-to-learn">
    <div className="container">
      <div className="row">
        <div className="col-md-6 learntoday">
          <h1>What Are You Learning With Us Today?</h1>
          <p>Access programs in Corporate Governance, Risk Management, AML/CFT, Business Marketing, legal, HR, compliance, resource management, and product development fields and more</p>
          <Link to="/Programme" className="btn todayforYou">Explore Programmes</Link>
            
        </div>
      </div>
      <div className="Aboutlearn learn-right">
                        <h3>Find your area of interest:</h3>
                        <select onChange={(e) => handleOptionClick(e.target.value)}>
                        <option value="/Legal-and-compliance">Legal & Compliance</option>
                            <option value="/Corporate-and-banking-sector">Corporate & Finance</option>
                            <option value="/Human-resource-and-product-development-studies">Human Resource Management</option>
                            <option value="/Product-Management">Products Management</option>
                            <option value="/Pre-degree">Pre-Degree Programme</option>
                        </select>
                    </div>
    </div>
  </section>



  <section className="about-us">
    <div className="container">
      <div className="row">
        <div className="col-md-6 about-lm">
          <h2>Our Story</h2>
        </div>
        <div className="col-md-6 about-lm">
          <div>
            <h2>Building t<span className='text-black'>he process</span></h2>
            <p>Lecturemeet began with a simple yet powerful vision and founded on the belief that education should be a lifelong pursuit, Lecturemeet diverse range of courses and programs is designed to meet the evolving needs of learners from all walks of life. </p>
          </div>
        </div>
        <div  className='col-md-6 about-lm'>
        <p>Born from a visionary quest to democratize education Lecturemeet was developed to make learning accessible to all, breaking the shackles of traditional barriers. Our journey began with a simple yet powerful vision. We started with a dream to democratize education, and today, we stand as a beacon of limitless learning possibilities without borders. The impact on individuals, organizations, and communities speaks volumes about the collective power of knowledge.</p>
        </div>
      </div>
    </div>
  </section>


<Blogupdates />

    </div>
  );
};

export default Blog;
