import React from 'react';
import { Helmet } from 'react-helmet';
import organisationteam from '../assets/images/organisationteam.jpg';
import teambuild from '../assets/images/teambuild.jpg';
import Chartorga from '../assets/images/Chartorga.jpg';
import Ourworkers from '../usercomponents/Ourworkers';
import Pricingplans from '../usercomponents/Pricingplans';
import TestimonialOrganisation from '../usercomponents/TestimonialOrganisation';
import { Link } from 'react-router-dom';
import Meta from '../components/Meta';




const OrganisationsPage = () => {
  return (
    <div className="container-mx-auto-p-4">
      <Helmet>
        <title>For Organisations | Team Upskilling | Lecturemeet</title>
        <Meta name="description" content="Lecturemeet for Organisations: Upskill your team with our professional courses. Enhance productivity and achieve your business goals with tailored learning solutions." />
<Meta name="keywords" content="Lecturemeet, organisations, team upskilling, professional courses, business goals, productivity, tailored learning, corporate training" />
<Meta name="author" content="Lecturemeet" />
<Meta name="viewport" content="width=device-width, initial-scale=1.0" />
<Meta charset="UTF-8" />
<Meta name="robots" content="index, follow" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Lecturemeet",
              "url": "https://lecturemeet.com/Organisations",
              "logo": "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n"
            }
          `}
        </script>
        
        <Meta property="og:title" content="For Organisations | Team Upskilling | Lecturemeet" />
        <Meta property="og:description" content="Upskill your team with our professional courses. Enhance productivity and achieve your business goals with tailored learning solutions." />
        <Meta property="og:image" content="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n" />
        <Meta property="og:url" content="https://lecturemeet.com/Organisations" />
        <Meta name="twitter:card" content="summary_large_image" />
        <Meta name="twitter:site" content="@LectureMeet" />
        <Meta name="twitter:creator" content="@LectureMeet" />
        
      </Helmet>


      <section className="prog-190-sector">
    <div className="container">
      <div className="row">
        <div className="col-md-6 pmsect-09">
          <h2>Employee Productivity</h2>
          <p>Tailored solutions for organizations to bridge the skills gap 
            through targeted learning paths, ensuring that employees 
            acquire the necessary skills for success.</p>
         

          <p>Lecturemeet designs a perfect learning path specific to 
            organizations needs, focusing on the skills and knowledge 
            required for each role and department from legal to HR, to 
            compliance to management.</p>

        </div>
        <div className="col-md-6 pmsect-09">
          <img src={teambuild} alt="Our Programmes" className="img-fluid" />
            </div>
      </div>
    </div>
  </section>




<section className="for-me-organis">
  <div className="container-organisation">
    <div className="flex-container">
      <div className="col-md-6 col-sm-12 organisa-lm">
        <h2>Organizational Management</h2>
        <p>Aimed at upskilling teams, enhancing employee performance, and driving organizational growth</p>
        <h2>Workflow Optimization</h2>
        <p>LectureMeet for Organizations is a comprehensive solution that empowers businesses to invest in their most valuable asset—their employees—by providing them with the skills and knowledge needed to succeed in today's dynamic and competitive business environment.</p>
      </div>
      <div className="col-md-6 col-sm-12 organisa-lm">
        <div className="pentagon-container">
          <div className="pentagon"></div>
          <img src={organisationteam} alt="Workflow" className="img-fluid pentagon-img" />
        </div>
      </div>
    </div>
  </div>
</section>





  <section>
<Ourworkers />
</section>


  <section className="">
    <div className="container">
      <div className="row">        
      <div className="col-md-6 pmsect-09">
          <img src={Chartorga} alt="Our Programmes" className="img-fluid" />
            </div>
        <div className="col-md-6 pmsect-09">
          <h2>Continuous learning, new skills</h2>
          <p> Lecturemeet adds value by empowering employees with relevant 
            skills and knowledge for career growth while helping 
            organizations enhance their workforce's capabilities, 
            leading to improved performance and competitiveness.</p>
         

          <p>By upskilling workforce, organizations can improve overall 
            performance, productivity, and employee engagement</p>
            <Link to="https://organisation.lecturemeet.com" className="btn btn-primary" style={{ textDecoration: 'none' }}>
  Onboard Team
</Link>
        </div>

      </div>
    </div>
  </section>


<section>

  <Pricingplans />
</section>


<section className="for-orga-primary-container">
  <div className="primary-org">
    <h2>Team solution driven</h2>
    <p>Providing a comprehensive learning solution that can help businesses stay competitive in today's rapidly evolving marketplace.</p>
  </div>
</section>


<section className="org-uniw8l">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="stats-left">
            <h2>Organisational impact</h2>
            <p>Lecturemeet has demonstrated outstanding performance in meeting the diverse learning and development needs of organizations</p>
            
            <br />
            <div className="arrow-circle">
              
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="stats-right">
            <div className="row colu-stats">
              <div className="col-3">
                <div className="stat" data-stat="Stat 1">
                  <h3>1k</h3>
                  <p>Teams</p>
                </div>
              </div>
              <div className="col-3">
                <div className="stat" data-stat="Stat 2">
                  <h3>20% </h3>
                  <p>Employee productivity increase</p>
                </div>
              </div>
              <div className="col-3">
                <div className="stat" data-stat="Stat 3">
                  <h3>75% </h3>
                  <p>Success stories</p>
                </div>
              </div>
              <div className="col-3">
                <div className="stat" data-stat="Stat 4">
                  <h3>32.9% </h3>
                  <p>Cost savings</p>
                </div>
              </div>
            </div>
            <hr className="divider" />
            <div className="stats-info">
  <h2>How beneficial is Lecturemeet to your workforce</h2>
  <ul className="stats-list"> 
    <li><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"/>
</svg>  Taught by industry experts</li>
    <li><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"/>
</svg>  Invest in team training without exceeding budget.</li>
    <li><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"/>
</svg> Up-to-date courses</li>
    <li><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"/>
</svg>  Flexibility in learning and work schedule</li>
  </ul>
</div>
<Link to="/Pricing" className="btn btn-primary" style={{ textDecoration: 'none' }}>
  See Plans
</Link>
          </div>
        </div>
      </div>
    </div>
  </section>




<section>
  <TestimonialOrganisation />
</section>
    </div>
  );
};

export default OrganisationsPage;
