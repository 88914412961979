import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/BlogUpdates.css'; 
import foretams from '../blog/Assets/imgs/foretams.jpg';
import learn from '../blog/Assets/imgs/learn.jpg';
import withoutborders from '../blog/Assets/imgs/withoutborders.jpg';
import neverstoplearning from '../blog/Assets/imgs/neverstoplearning.jpg';
import compliance from '../blog/Assets/imgs/compliance.jpg';
import employee from '../blog/Assets/imgs/employee-train.jpg';
import regcomp from '../blog/Assets/imgs/regcomp.jpg';

const BlogUpdates = () => {
  const blogPosts = [
    { id: 1, imageUrl: regcomp, title: 'Working in the financial sector as a compliance officer: What you need to know and how to navigate challenges', link: '/blog/Working-in-the-financial-sector-as-a-compliance-officer' },
    { id: 1, imageUrl: compliance, title: '6 Job Roles in Compliance Certifications: Benefits, Roles, and Pay Grades', link: '/blog/compliance-certifications-5-job-roles-and-benefits' },
    { id: 2, imageUrl: foretams, title: 'Maximizing your online learning experience: Tips for students', link: '/blog/Maximizing-Your-Learning-Experience-Tips-for-Online-Students' },
    { id: 3, imageUrl: learn, title: 'The future of professional development: how online courses are changing the game', link: '/blog/The-future-of-professional-development' },
    { id: 4, imageUrl: withoutborders, title: 'Balancing work and study: time management tips for busy professionals', link: '/blog/Balancing-work-and-study' },
    { id: 5, imageUrl: neverstoplearning, title: 'Career advancement through certification with Lecturemeet: why it matters and how to get started', link: '/blog/Career-advancement-through-certification-courses-on-lecturemeet' },
    { id: 6, imageUrl: employee, title: 'Why should a business organisation train employees : 5 reasons', link: '/blog/employee-training-for-teams-organsaitions' },
  ];


  return (
    <div className="UpdatesBlog-container">
      <header className="UpdatesBlog-header">
        <h1>Our Career Guide Articles</h1>
      </header>
      <div className="UpdatesBlog">
        {blogPosts.map((post) => (
          <Link to={post.link} key={post.id} className="UpdatesBlog-item">
            <img src={post.imageUrl} alt={post.title} className="UpdatesBlog-image" />
            <h2 className="UpdatesBlog-title">{post.title}</h2>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogUpdates;
