import React from 'react';
import { Helmet } from 'react-helmet';
import productManage from '../assets/images/product.jpg';
import Product from '../usercomponents/pricingplans/Product';
import Meta from '../components/Meta';





const ProductManagement = () => {
  const location = 'Nigeria';
  return (
    <div className="container-mx-auto-p-4">
      <Helmet>
        <title>Product Management | LectureMeet</title>
        <Meta name="description" content="Master the skills needed to excel in product management with Lecturemeet's Product Management School. Explore our courses designed to boost your career and drive product success." />
<Meta name="keywords" content="Lecturemeet, Product Management School, product management, professional learning, career advancement, education, product success" />
<Meta name="author" content="Lecturemeet" />
<Meta name="viewport" content="width=device-width, initial-scale=1.0" />
<Meta charset="UTF-8" />
<Meta name="robots" content="index, follow" />

<script type="application/ld+json">
    {`
        {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Lecturemeet",
        "url": "https://lecturemeet.com/Product-Management",
        "logo": "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n"
        }
    `}
</script>

<Meta property="og:title" content="Product Management School | Lecturemeet" />
<Meta property="og:description" content="Master the skills needed to excel in product management with Lecturemeet's Product Management School. Explore our courses designed to boost your career and drive product success." />
<Meta property="og:image" content="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n" />
<Meta property="og:url" content="https://lecturemeet.com/Product-Management" />
<Meta name="twitter:card" content="summary_large_image" />
<Meta name="twitter:site" content="@LectureMeet" />
        <Meta name="twitter:creator" content="@LectureMeet" />

      
      
      </Helmet>

      <section className="Corporate-09Sec">
  <div className="corp-sect-container">
    <div className="corp-colum">
      <h1 className='text-3xl font-bold mb-4 text-left' style={{ textAlign: 'left', color: 'black'}}>Learning Product Management </h1>
      <p className="mb-4" style={{ textAlign: 'left', color: 'black', fontSize: '20px', }}>
      This course aims to provide a comprehensive understanding of product 
      management principles and practices, preparing you for roles as 
      product managers in various industries. From ideation to product 
      analytics and metrics , product cycle,  market research to launch. 
      
    
      </p>
      <p className="mb-4" style={{ textAlign: 'left', color: 'black', fontSize: '20px',}}>
      By enrolling in this course, individuals can unlock their potential 
      and become proficient product managers, ready to lead with confidence
      </p>

    </div>
    <div className="corp-colum">
      <img src={productManage} alt="Corporate course" className="img-fluid" />
    </div>
  </div>
</section>

<div className="columns-container-corporate" style={{ display: 'flex', backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
  <div style={{ flex: '1', marginRight: '20px', textAlign: 'left' }}>
    <h4 style={{ marginBottom: '20px', fontSize: '36px', fontWeight: 'bold' }}>Learning module</h4>
    <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
      <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg> Overview of Product Management and Role of a Product Manager</li>
      <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg> Importance of Product Management in Business</li>
      <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg> Product Development Process</li>

<li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg> Product management Strategy and Lifecycle Management</li>

<li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg> Product Portfolio Management</li>
<li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg> Product Launch and Marketing</li>
<li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg>  Product Analytics and Metrics</li>
<li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg>  Product Leadership and Team Management</li>
    </ul>
  </div>
  <div style={{ flex: '1', marginLeft: '20px' }}>
    <h4 style={{ marginBottom: '20px', fontSize: '36px', fontWeight: 'bold' }}> <br/></h4>
    <ul style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
      <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg>  Building and Managing Product Teams</li>
      <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg>  Product Leadership and Team Management
        <ul style={{ listStyleType: 'none', padding: '0', marginLeft: '20px', textAlign: 'left' }}>
          <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg>  Leadership Skills for Product Managers</li>
          <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg>  Stakeholder Management</li>
          <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg>  Conflict Resolution and Decision Making</li>
        </ul>
      </li>
    </ul>
  </div>
</div>




<section className="columns-container-corporate" style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
  <h4 style={{ textAlign: 'left', marginBottom: '20px', fontSize: '36px', fontWeight: 'bold' }}>Learning expectations</h4>
  <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
    <li style={{ marginBottom: '10px' }}>Gain a comprehensive understanding of product management.</li>
    <li style={{ marginBottom: '10px' }}>Learn about the role of a product manager and the importance of product management in business and startups.</li>
    <li style={{ marginBottom: '10px' }}>Understand the product development process and strategies for managing product lifecycles</li>
    <li style={{ marginBottom: '10px' }}>Enhance leadership and team management skills.</li>
    <li style={{ marginBottom: '10px' }}>Prepare for a career as a product manager.</li>

  </ul>
</section>

<br />
<br />
<Product location={location}/>





    </div>
  );
};

export default ProductManagement;
