import React from 'react';
import '../../assets/css/Pre-degree.css';

const LearningModel = () => {
    return (
        <section className="learning-model" id="learning-model">
            <div className="container">
                <h2>Learning Model</h2>
                <div className="accordion" id="learningModelAccordion">
                    {/* First Session */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="pre-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Session Overview
                                <span className="material-icons float-end">+</span>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#learningModelAccordion">
                            <div className="pre-accordion-body">
                                <table className="predegree-table">
                                    <thead>
                                        <tr>
                                            <th>Course/Topic</th>
                                            <th>Unit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                            <td>Introduction to Mass Communication</td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td> Introduction to Reporting and Newswriting</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>History of Nigerian Mass Media</td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td>English for Mass Communication</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Introduction to Sociology </td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td>Introduction to Mass Communication</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Principles and Practice of Public Relations</td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td>Advertising Principles and Practice</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Ethics and Law of Mass Communication </td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Radio/TV Programme Writing and Production</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>English for Mass Communication</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Introduction To Sociology</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Fundamentals of Communication</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Forms and Context of Communication</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Media of Communication & Ethics</td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td>Public Relations Basics</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Mass Communication Theories</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Advanced Media Studies</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Digital Communications</td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td>Strategic Communication</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Media Production Techniques</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Introduction To Political Science</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Introduction To Psychology</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Long Essay</td>
                                            <td>5</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="text-end font-weight-bold">Total Units: 69</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="predegree-table">
                                    <thead>
                                        <tr>
                                            <th>Activity</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Assessment</td>
                                            <td>Evaluation of understanding through MCQ and written assessments</td>
                                        </tr>
                                        <tr>
                                            <td>Revision</td>
                                            <td>Review of course in the sessions</td>
                                        </tr>
                                        <tr>
                                            <td>Exams</td>
                                            <td>Final examination</td>
                                        </tr>
                                        <tr>
                                            <td>Result and Certification</td>
                                            <td>Graduationa and certificate collection</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="text-end font-weight-bold">Pass Grade: 75% Minimum + Attendance</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </section>
    );
};

export default LearningModel;
