import React from 'react';
import '../assets/css/AdmissionRequirements.css';
import { Helmet } from 'react-helmet';
import Meta from '../components/Meta';


const AdmissionRequirements = () => {
    return (
      <div className="admission-requirements">
          <Helmet>
        <title>Admissions | Lecturemeet </title>
        <Meta name="description" content="Enroll in our Pre-Degree Programme and professional courses. Start your journey towards career advancement and personal growth today." />
<Meta name="keywords" content="Lecturemeet, admission, pre-degree programme, professional courses, enrollment, career advancement, education" />
<Meta name="author" content="Lecturemeet" />
<Meta name="viewport" content="width=device-width, initial-scale=1.0" />
<Meta charset="UTF-8" />
<Meta name="robots" content="index, follow" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Lecturemeet",
              "url": "https://lecturemeet.com/Admission-for-pre-degree",
              "logo": "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n"
            }
          `}
        </script>
        
        <Meta property="og:title" content="Admissions - Lectuemeet" />
        <Meta property="og:description" content="Enroll in our Pre-Degree Programme and professional courses. Start your journey towards career advancement and personal growth today" />
        <Meta property="og:image" content="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n" />
        <Meta property="og:url" content="https://lecturemeet.com/Admission-for-pre-degree" />
        <Meta name="twitter:card" content="summary_large_image" />
        <Meta name="twitter:site" content="@LectureMeet" />
        <Meta name="twitter:creator" content="@LectureMeet" />

        
      </Helmet>
        <header className="header">
          <h1>Lecturemeet Pre-Degree Programme<br />
          Admission Requirements</h1>
        </header>
        <section className="section intro">
          <p>Lecturemeet is inviting applications from qualified candidates 
            for admission into its 3 months foundational crash Pre-Degree 
            programme. <br />
             <br />
            It offers students the opportunity to gain a solid 
            foundation in their chosen fields of study. The available programmes on Lecturemeet are Law, Mass Communication, and Business 
            Administration.</p>
        </section>
        <section className="section about">
          <h2>About the Pre-Degree Programmes</h2>
          <p>The Pre-Degree Programme at Lecturemeet is designed to prepare candidates for admission into 100 Level degree programmes within any University.</p>
          <br />
          <p>The Pre-Degree Programmes at Lecturemeet are designed for 
            students who have completed their secondary education 
            and are looking to gain foundational knowledge in their chosen 
            fields before advancing to higher education. 
            <br />
            <br />These programmes provide an intensive and comprehensive 
            introduction to key areas of study, equipping students with 
            the skills and knowledge necessary for academic and professional 
            success.</p>
        </section>
        <section className="section entry-requirements">
          <h2>Entry Requirements (Specific to Nigeria curriculum)</h2>
          <ul>
            <li>Must be a Nigerian or studied in any African country</li>
            <li>Must have completed secondary education (SSCE/WAEC/NECO) with a minimum of five credits, including English Language and Mathematics.</li>
            <li>Must be at least 15 years old at the time of application.</li>
            <li>Submission of a completed application form along with relevant academic results or certificates.</li>
            <li>Pass the entrance assessment by Lecturemeet.</li>
          </ul>
        </section>
        <section className="section duration">
          <h2>Programme Duration</h2>
          <p>Each Pre-Degree Programme lasts for 3 months, providing a focused and intensive learning experience.</p>
        </section>
        <section className="section payment-mode">
          <h2>Payment Mode</h2>
          <ul>
            <li>Full Payment: A one-time payment for the entire programme.</li>
            <li>Instalment Payment: Payments can be made in two instalments over the duration of the programme.</li>
          </ul>
        </section>
        <section className="section programme-descriptions">
          <h2>Programme Descriptions</h2>
          <h3>Mass Communications</h3>
          <p>This programme introduces students to the dynamic world of mass media, covering areas such as journalism, broadcasting, public relations, and advertising. Students will gain an understanding of the role of media in society, learn the basics of news writing and reporting, and explore the principles of effective communication and media ethics.</p>
          <h3>Foundation in Law</h3>
          <p>The Foundation in Law programme is ideal for students considering a career in law. This course provides a strong foundational background in law for further studies, covering various aspects of law in the first two years of higher education, including legal methods, contract law, criminal law, and family law. Students will develop critical thinking skills and an understanding of legal principles and their application.</p>
          <h3>Business Administration</h3>
          <p>The Business Administration programme is designed to equip students with essential knowledge and skills in business management. Students learn about various aspects of business, including management principles, marketing strategies, and financial management. The course prepares students for further studies in business and management, providing a solid foundation for a successful career in the business world.</p>
        </section>
        <section className="section additional-details">
          <h2>Additional Details</h2>
          <ul>
            <li>Mode of Study: The programmes are delivered through a combination of online lectures, assessments and examinations ensuring a comprehensive learning experience. Attendanc rate for a student to undertake the exam and earn certificate is 75%. Courses are based on units similar to university approacha and standard.</li>
            <li>Certification: Upon successful completion of the programme, students will receive a digital or physical certificate from Lecturemeet, recognizing their achievement and readiness for further studies or professional opportunities.</li>
            <li>Support Services: Lecturemeet provides academic support, including tutoring and mentoring, to help students succeed in their studies. Career counselling services are also available to guide students in making informed decisions about their future careers.</li>
            <li>Career: The certificate earned through the Pre-Degree Program can help students secure internships in their chosen fields, providing practical experience and exposure to industry practices.</li>
          </ul>
        </section>
      </div>
    );
  };
  
  export default AdmissionRequirements;