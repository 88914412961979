import React from 'react';

const TestimonialsSection = () => {
  return (
    <section className="testimonials-section">
        <div className='globalheader'>
        <h2>Our Global Impact on Teams</h2>
        </div>
      <div className="testimonial">        
        <p className="quote">"Lecturemeet has transformed our team's skill set. The flexibility of their programs and the quality of instruction have been invaluable."</p>
        <p className="author">- Christianah Obudu - HR Manager</p>
      </div>
      <div className="testimonial">
        <p className="quote">"Lecturemeet's courses are not just informative but transformative. "</p>
        <p className="author">- David Samuel</p>
      </div>
      <div className="testimonial">
        <p className="quote">"I absolutely love Lecturemeet. I've learned so much and have been able to apply it directly to my work."</p>
        <p className="author">- Prince Obi, Associate Counsel, F.T. Okorotie, SAN & Co</p>
      </div>
      <div className="testimonial">
        <p className="quote">"Our team's productivity has increaded and the practical skills learned have had a direct impact on our work."</p>
        <p className="author">- Paye Epang - Founder Adsboard</p>
      </div>
    </section>
  );
};

export default TestimonialsSection;
