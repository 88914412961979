// src/currencyDetector.js
export const detectCurrency = (country) => {
    switch (country) {
      case 'Nigeria':
        return 'NGN';
      case 'United Kingdom':
        return 'GBP';
      case 'Canada':
        return 'CAD';
      case 'Ghana':
        return 'GHS';
      case 'Rwanda':
        return 'RWF';
      case 'Kenya':
        return 'KES';
      case 'South Africa':
        return 'ZAR';
      case 'Uganda':
        return 'UGX';
      case 'Netherlands':
        return 'EUR';
      case 'Australia':
        return 'AUD';
      default:
        return 'USD'; // Default to USD if country is not found
    }
  };
  
  export const determineCourseCategory = (selectedCourse) => {
    if (!selectedCourse) return 'Other';
    if (selectedCourse.includes('Business')) {
      return 'Business';
    } else if (selectedCourse.includes('Law') || selectedCourse.includes('Legal') || selectedCourse.includes('Compliance')) {
      return 'LawLegalCompliance';
    } else if (selectedCourse.includes('Tech')) {
      return 'Tech';
    } else {
      return 'Other';
    }
  };
  
  export const convertAmountToCurrency = (amount, currencyCode) => {
    const exchangeRates = {
      'USD': 1,
      'EUR': 0.85,
      'NGN': 350,
      'GBP': 0.75,
      'JPY': 110,
      'CAD': 1.25,
      'AUD': 1.35,
      'CHF': 0.92,
      'CNY': 6.45,
      'INR': 73,
      'MXN': 20,
      'BRL': 5.2,
      'ZAR': 14.5,  
      'RUB': 74,
      'KRW': 1150,
      'SGD': 1.35,
      'GHS': 11,     
      'RWF': 1150,   
      'KES': 110,    
    };
    return amount * (exchangeRates[currencyCode] || 1);
  };
  
  export const getCurrencySymbol = (currencyCode) => {
    const symbols = {
      'USD': '$',
      'EUR': '€',
      'NGN': '₦',
      'GBP': '£',
      'JPY': '¥',
      'CAD': 'C$',
      'AUD': 'A$',
      'CHF': 'CHF',
      'CNY': '¥',
      'INR': '₹',
      'MXN': '₱',
      'BRL': 'R$',
      'ZAR': 'R',
      'RUB': '₽',
      'KRW': '₩',
      'SGD': 'S$',
      'GHS': '₵',
      'RWF': 'RF',
      'KES': 'KSh',
    };
    return symbols[currencyCode] || '$';
  };
  
  export async function getUserLocation() {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      const country = data.country_name || 'Unknown';
      const state = data.region || 'Unknown';
      const ipAddress = data.ip || 'Unknown';
      return { country, state, ipAddress };
    } catch (error) {
      console.error('Error fetching user location:', error);
      return { country: 'Unknown', state: 'Unknown', ipAddress: 'Unknown' };
    }
  }
  