import React from 'react';
import { Link } from 'react-router-dom';
import videoload from '../assets/video/videoload.mp4';

const VideoSection = () => {
  return (
    <section className="video-section">
      <div className="for-indKO">
        <h2>Switch Care<span className='text-black'>er Paths</span></h2>
        <p>
          Learn, unlearn and relearn without borders with Lecturemeet
        </p>
        <Link to="/Programme" className="btn btn-primary" style={{ textDecoration: 'none' }}>
          Learn More
        </Link>
      </div>
      <div className="for-indKO-right">
        <video
          controls
          autoPlay
          loop
          width="100%"
          height="315"
          src={videoload}
          title="LectureMeet Video"
        >
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  );
};

export default VideoSection;
