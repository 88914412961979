import React from 'react';
import { Helmet } from 'react-helmet';
import GenerlaAsked from '../usercomponents/GenerlaAsked';


const GenralFaqs = () => {
  return (
    <div className="container-mx-auto-p-4">
      <Helmet>
        <title>Know about us | FAQ's | Lecturemeet </title>
        <meta name="description" content="Explore frequently asked questions (FAQs) about Lecturemeet. Find answers to common queries about our programs, enrollment process, payment options, and more." />
<meta name="keywords" content="Lecturemeet, FAQs, frequently asked questions, programs, enrollment, payment options" />
<meta name="author" content="Lecturemeet" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta charset="UTF-8" />
<meta name="robots" content="index, follow" />

<script type="application/ld+json">
    {`
        {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Lecturemeet",
        "url": "https://lecturemeet.com/Support/faqs",
        "logo": "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n"
        }
    `}
</script>

<meta property="og:title" content="FAQs | Lecturemeet" />
<meta property="og:description" content="Explore frequently asked questions (FAQs) about Lecturemeet. Find answers to common queries about our programs, enrollment process, payment options, and more." />
<meta property="og:image" content="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n" />
<meta property="og:url" content="https://lecturemeet.com/Support/faqs" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:site" content="@LectureMeet" />
        <meta name="twitter:creator" content="@LectureMeet" />
      </Helmet>

      <section class="faq-bg-section">
  <div class="About-container">
    <div class="about-content">
      <h2>Get to know about us</h2>
      <p>Knowldge is power. Find our resource center helpful to answer most of your questions</p>
    </div>
  </div>
</section>




<section>
    <GenerlaAsked />
</section>

    </div>
  );
};

export default GenralFaqs;
