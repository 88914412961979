import React from 'react';
import { useParams } from 'react-router-dom';
import '../css/apply.css';
import { jobs } from '../jobs';


const Apply = () => {
  const { id } = useParams();
  const job = jobs.find(job => job.id === id);

  return (
    <div className="Jobs">
      <div className="apply-page">
        <h1>Apply for Job: {job.title}</h1>
        <form>
          <label>
            Name:
            <input type="text" name="name" required />
          </label>
          <label>
            Email:
            <input type="email" name="email" required />
          </label>
          <label>
            Resume:
            <input type="file" name="resume" required />
          </label>
          <button type="submit">Submit Application</button>
        </form>
      </div>
      
      <div className='jobapply'>
        <h1 className='text-center'>Role:  <strong>{job.title}</strong></h1>
        <iframe
          title={`Employee Application For the Role of: ${job.title}`}
          className="airtable-embed airtable-dynamic-height"
          src="https://airtable.com/embed/app9byZYdCUy39ZEQ/shrvSQtZVLqZu0Zds"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="1901"
          style={{ background: 'transparent', border: '1px solid #ccc' }}
        ></iframe>
      </div>
    </div>
  );
};

export default Apply;
