import React from 'react';
import { Helmet } from 'react-helmet';
import verified from '../assets/images/verified.jpg';
import predgreecourse from '../assets/images/predgreecourse.jpg';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import CertificateFAQs from '../usercomponents/CertificateFAQs';
import Meta from '../components/Meta';


const AboutPage = () => {
  const navigate = useNavigate();

  const handleOptionClick = (redirectUrl) => {
      if (redirectUrl) {
          navigate(redirectUrl);
      }
  };

  return (
    <div className="About-container container-mx-auto-p-4">
      <Helmet>
        <title>Certificate | Lecturemeet </title>
        <Meta name="description" content="Whether for career advancement, personal growth, or professional recognition, Lecturemeet certifications empower learners to achieve their goals and excel in their chosen fields." />
       
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Lecturemeet",
              "url": "https://lecturemeet.com/certificate",
              "logo": "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n"
            }
          `}
        </script>
        
        <Meta property="og:title" content="About - Lectuemeet |  Professional Certification" />
        <Meta property="og:description" content="Providing tangible proof of learners’ achievements and skills. With a focus on accessibility, verification, and global recognition." />
        <Meta property="og:image" content="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n" />
        <Meta property="og:url" content="https://lecturemeet.com/certificate" />
        <Meta name="twitter:card" content="summary_large_image" />
        <Meta name="twitter:site" content="@LectureMeet" />
        <Meta name="twitter:creator" content="@LectureMeet" />
      </Helmet>

      

  <section className="our-Mission">
    <div className="growth-mission-container">
      <div className="row">
        <div className="col-md-6 goalsLm">
          <img src={predgreecourse} alt="Mission " className="img-fluid" />
        </div>
        <div className="col-md-6 goalsLm">
          <div className="mission-text">
            <h2>Enrolling in Lecturemeet</h2>
            <p>Certifications from Lecturemeet are designed to provide 
                learners with recognized credentials that can boost their 
                resumes and career prospects. 
                
                <br />
                <br />
                These certifications validate
                the knowledge and skills acquired by learners, ensuring 
                they meet industry standards and requirements.</p>
                <Link to="https://certificate.lecturemeet.com" className="btn btn-primary" style={{ textDecoration: 'none' }}>
 Verify Certificate
</Link>
          </div>
        </div>
      </div>
    </div>
  </section>



      <section className="about-us">
    <div className="container">
      <div className="row">
        <div className="col-md-6 about-lm">
          <h2>Personal Growth <br />& Career Advancement</h2>
        <p>Born from a visionary quest to democratize education Lecturemeet was developed to make learning accessible to all, breaking the shackles of traditional barriers. Our journey began with a simple yet powerful vision. We started with a dream to democratize education, and today, we stand as a beacon of limitless learning possibilities without borders. The impact on individuals, organizations, and communities speaks volumes about the collective power of knowledge.</p>

        </div>
        <div className="col-md-6 about-lm">
          <div>
            <h2>Issuance of t<span className='text-black'>he certificate </span></h2>
            <p>Certifications from Lecturemeet can significantly enhance a learner’s career prospects by providing recognized proof of their skills and knowledge, as employers value certifications as they demonstrate a commitment to professional development and continuous learning. </p>
            <Link to="https://organisation.lecturemeet.com" className="btn btn-primary" style={{ textDecoration: 'none' }}>
  Onboard Team
</Link>
</div>
        </div>
      </div>
    </div>
  </section>



  <section className="our-Mission growthpath">
    <div className="growth-mission-container">
      <div className="row">       
        <div className="col-md-6 goalsLm">
          <div className="mission-text">
            <h2>Verification</h2>
            <p>Lecturemeet offers a certificate verification feature, allowing employers and other entities to verify the authenticity of certificates. This is typically done through a unique verification id included on the certificate.</p>
          </div>
        </div>
        <div className="col-md-6 goalsLm">
          <img src={verified} alt="Our Growth path" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>




<section className="what-to-learn">
    <div className="container">
      <div className="row">
        <div className="col-md-6 learntoday">
          <h1>What Are You Learning With Us Today?</h1>
          <p>Access programs in Corporate Governance, Risk Management, AML/CFT, Business Marketing, legal, HR, compliance, resource management, and product development fields and more</p>
          <Link to="/Programme" className="btn todayforYou">Explore Programmes</Link>
            
        </div>
      </div>
      <div className="Aboutlearn learn-right">
                        <h3>Find your area of interest:</h3>
                        <select onChange={(e) => handleOptionClick(e.target.value)}>
                        <option value="/Legal-and-compliance">Legal & Compliance</option>
                            <option value="/Corporate-and-banking-sector">Corporate & Finance</option>
                            <option value="/Human-resource-and-product-development-studies">Human Resource Management</option>
                            <option value="/Product-Management">Products Management</option>
                            <option value="/Pre-degree">Pre-Degree Programme</option>
                            <option value="/Pay-per-course">Pay pay course</option>
                        </select>
                    </div>
    </div>
  </section>




<section>
  <CertificateFAQs />
</section>




    </div>
  );
};

export default AboutPage;
