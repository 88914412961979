import React from 'react';
import '../../assets/css/Pre-degree.css';

const LearningModel = () => {
    return (
        <section className="learning-model" id="learning-model">
            <div className="container">
                <h2>Learning Model</h2>
                <div className="accordion" id="learningModelAccordion">
                    {/* First Session */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="pre-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Session Overview
                                <span className="material-icons float-end">+</span>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#learningModelAccordion">
                            <div className="pre-accordion-body">
                                <table className="predegree-table">
                                    <thead>
                                        <tr>
                                            <th>Course/Topic</th>
                                            <th>Unit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                            <td>Introduction to Business</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Introduction to Quantitative Techniques for Business</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Principles of Accounting</td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td>Principles of Microeconomics</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Communication in English</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Logic and Philosophy</td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td>Introduction to Political Science</td>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <td>Principles of Management</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Principles of Marketing</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Business Mathematics & Statistics</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Financial Accounting</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Introduction To Sociology</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Advertising and Sales Promotion</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Principles of Insurance</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>International Trade and Finance</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Business Law</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Introduction to Entrepreneurial Skills</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Human Resources Management</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Company Law</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Marketing Management</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Small Business Management</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Long Essay</td>
                                            <td>5</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="text-end font-weight-bold">Total Units: 65</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="predegree-table">
                                    <thead>
                                        <tr>
                                            <th>Activity</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Assessment</td>
                                            <td>Evaluation of understanding through MCQ and written assessments</td>
                                        </tr>
                                        <tr>
                                            <td>Revision</td>
                                            <td>Review of course in the sessions</td>
                                        </tr>
                                        <tr>
                                            <td>Exams</td>
                                            <td>Final examination</td>
                                        </tr>
                                        <tr>
                                            <td>Result and Certification</td>
                                            <td>Graduationa and certificate collection</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="text-end font-weight-bold">Pass Grade: 75% Minimum + Attendance</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </section>
    );
};

export default LearningModel;
