import React from 'react';
import '../assets/css/Policy.css';
import { Helmet } from 'react-helmet';


const RefundPolicyPage = () => {
  return (
    <div className="refund-policy-container">

<Helmet>
        <title>Refund Policy Terms | Lecturemeet </title>
        <meta name="description" content="Read Lecturemeet's Refund Policy to understand our terms for refunds, cancellations, and returns for courses and services purchased through our website." />
<meta name="keywords" content="Lecturemeet, Refund Policy, refunds, cancellations, returns, courses, services" />
<meta name="author" content="Lecturemeet" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta charset="UTF-8" />
<meta name="robots" content="index, follow" />

<script type="application/ld+json">
    {`
        {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Lecturemeet",
        "url": "https://lecturemeet.com/terms/refunds",
        "logo": "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n"
        }
    `}
</script>

<meta property="og:title" content="Refund Policy | Lecturemeet" />
<meta property="og:description" content="Read Lecturemeet's Refund Policy to understand our terms for refunds, cancellations, and returns for courses and services purchased through our website." />
<meta property="og:image" content="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n" />
<meta property="og:url" content="https://lecturemeet.com/terms/refunds" />
<meta name="twitter:card" content="summary_large_image" />

      </Helmet>
      <>
      <h1>Refund Policy</h1>

      <section className="refund-section">
        <h2>Introduction</h2>
        <p>
          We strive to provide our users with high-quality courses and services. If you are not satisfied with your purchase, we offer refunds under the following conditions.
        </p>
      </section>

      <section className="refund-section">
        <h2>Eligibility for Refunds</h2>
        <h3>Courses</h3>
        <p>
          If you are not satisfied with a course, you may request a refund within 14 days of purchase. To be eligible for a refund, the course must not have been completed or significantly used. We reserve the right to deny a refund request if we determine that the course has been significantly used or the request is fraudulent.
        </p>

       

        <h3>Events</h3>
        <p>
          Refunds for events are only available if the event is canceled or rescheduled. In such cases, you will be notified, and a full refund will be issued.
        </p>
      </section>

      <section className="refund-section">
        <h2>Requesting a Refund</h2>
        <p>
          To request a refund, please contact our support team at refunds@lecturemeet.com with your account and payment details and the reason for the refund request. We will review your request and notify you of your refund request via your provided communication channels.
        </p>
      </section>

      <section className="refund-section">
        <h2>Processing Refunds</h2>
        <p>
          Approved refunds will be processed to the original payment method within 7-10 business days. If you do not receive your refund within this timeframe, please contact us at refunds@lecturemeet.com.
        </p>
      </section>

      <section className="refund-section">
      <h3>Changes to Terms</h3>
        <p>
          Lecturemeet reserves the right to modify these terms at any time. We will notify you of any changes by updating the terms on our website. Continued use of our services after changes constitutes acceptance of the new terms.
        </p>

        <h2>Contact Information</h2>
        <p>
          If you have any questions about these terms and policies, please contact us at:
        </p>
        <p>
          Email: support@lecturemeet.com<br />
          Abuja, FCT, Nigeria
        </p>
      </section>

      </>


    </div>
  );
};

export default RefundPolicyPage;
