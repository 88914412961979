import React from 'react';
import arrow from '../assets/images/arrow.svg';
import predegree from '../assets/images/predgreecourse.jpg';
import productmanage from '../assets/images/productmanage.jpg';
import corporate from '../assets/images/corporate.jpg';
import CorporateFinance from '../assets/images/Corporate&Finance.jpg';

const Card = ({ title, imageUrl, buttonUrl, buttonText }) => (
  <div className="card">
    <div className="card-inner">
      <div className="card-front">
        <img src={imageUrl} alt={title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </div>
      <div className="card-back">
        <h2>{title}</h2>
        <a href={buttonUrl} className="btn">
          {buttonText}
          <img src={arrow} alt="Arrow" style={{ marginLeft: '5px' }} />
        </a>
      </div>
    </div>
  </div>
);

const cardsData = [
  { id: 1, title: 'Corporate & Finance', imageUrl: CorporateFinance, buttonUrl: '/Corporate-and-banking-sector', buttonText: 'Explore Corporate & Finance' },
  { id: 2, title: 'Legal & Compliance', imageUrl: corporate, buttonUrl: '/Legal-and-compliance', buttonText: 'Explore Legal & Compliance' },
  { id: 3, title: 'Products & Management', imageUrl: productmanage, buttonUrl: '/Product-Management', buttonText: 'Explore Products & Management' },
  { id: 4, title: 'Pre-Degree Programme', imageUrl: predegree, buttonUrl: '/Pre-degree', buttonText: 'Explore Pre-degree' },
];

const FlipCard = () => (
  <div>
    <div className='explore-card'>
      <h1>Explore learning areas</h1>
      <p>Our diverse course offerings and programs provides for <br />
        every industry professionals at every stage of their career, <br />
        from entry-level to experienced professionals looking to <br />
        upskill or specialize in a new area.</p>
    </div>
    <div className="container-FlipCard">
      {cardsData.map(({ id, title, imageUrl, buttonUrl, buttonText }) => (
        <Card key={id} title={title} imageUrl={imageUrl} buttonUrl={buttonUrl} buttonText={buttonText} />
      ))}
    </div>
  </div>
);


export default FlipCard;
