import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Updates from '../blog/Updates';


const GuidesandSupport = () => {
  return (
    <div className="container-mx-auto-p-4">
      <Helmet>
        <title>Guide and Resource Center | Lecturemeet </title>
<meta name="description" content="Explore Lecturemeet's Guide and Resource Center for helpful resources, articles, and guides on professional learning, career advancement, and personal development. Find valuable insights and tips to enhance your learning experience." />
<meta name="keywords" content="Lecturemeet, Guide and Resource Center, resources, articles, guides, professional learning, career advancement, personal development" />
<meta name="author" content="Lecturemeet" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta charset="UTF-8" />
<meta name="robots" content="index, follow" />

<script type="application/ld+json">
    {`
        {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Lecturemeet",
        "url": "https://lecturemeet.com/Support/guides",
        "logo": "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n"
        }
    `}
</script>

<meta property="og:title" content="Guide and Resource Center | Lecturemeet" />
<meta property="og:description" content="Explore Lecturemeet's Guide and Resource Center for helpful resources, articles, and guides on professional learning, career advancement, and personal development. Find valuable insights and tips to enhance your learning experience." />
<meta property="og:image" content="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n" />
<meta property="og:url" content="https://lecturemeet.com/Support/guides" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:site" content="@LectureMeet" />
        <meta name="twitter:creator" content="@LectureMeet" />

      </Helmet>

      <section class="faq-bg-section">
  <div class="About-container">
    <div class="about-content">
      <h2>Trying to navigate through our platform ?</h2>
      <p>Find from the most useful links on getting started with us</p>
    </div>
  </div>
</section>





<section>
<div className="Guide-container">
      <header className="header">
        <h1>Navigate Our Platform</h1>
      </header>
      <div className="guidepage">
        <Link to="/About" className="guidepage-item">About</Link>
        <Link to="/terms/policy" className="guidepage-item">Terms</Link>
        <Link to="/Support/faqs" className="guidepage-item">FAQ's</Link>
        <Link to="/Pricing" className="guidepage-item">Pricing</Link>
      </div>
    </div>
</section>

<br />
<br />


<Updates />

    </div>
  );
};

export default GuidesandSupport;
