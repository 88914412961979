import React from 'react';


const HeroSection = () => {
  return (
    <section className="hero-me">
      <div className="container">
        <div className="herotab">
          <h1>Begin your learning growth <br />journey today</h1>
          <p>Our mission is finding a safe and secure means for your perosnal and business team develeopment.  <br />
          We are always keen to finding the perfect solution for your learning goals</p>
          <div className="hero-data">
            <div className="hero-stat">
              <h3>100k+</h3>
              <p>Learners</p>
            </div>
            <div className="hero-stat">
              <h3>85%</h3>
              <p>Completion Rate</p>
            </div>
            <div className="hero-stat">
              <h3>1k+</h3>
              <p>Organizations</p>
            </div>
            <div className="hero-stat">
              <h3>20+</h3>
              <p>Programs</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
