import React, { useState, useEffect } from 'react';
import testimonial1Image from '../assets/images/person4.jpg';
import testimonial2Image from '../assets/images/person2.jpg';
import testimonial3Image from '../assets/images/person3.jpg';

const TestimonialSection = () => {
  const testimonialsData = [
    { id: 1, header: 'Alaere Jonah', quote: 'Studying Mass Communications fundamentals on Lecturemeet gave me a lead in my first 2 years year at the university. Its crash pre-degree program gave exposure into for the practical knowledge and skills.', imageUrl: testimonial1Image },
    { id: 2, header: 'Jennifer Lois Anderson', quote: 'Studying the Foundation in Law at Lecturemeet was a challenging yet rewarding experience. The course structure was well-organized focused curriculum.', imageUrl: testimonial2Image },
    { id: 3, header: 'Esther Tiemo', quote: 'The Mass Communications course at Lecturemeet Pre-degree program was incredibly insightful. I learned practical skills that were directly applicable to my university coursework. It was a fantastic foundation for my future studies.', imageUrl: testimonial3Image }
  ];

  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonialIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
    }, 5000); 
    
    return () => clearInterval(interval);
  }, [testimonialsData.length]);

  const handlePrevTestimonial = () => {
    setCurrentTestimonialIndex((prevIndex) => (prevIndex - 1 + testimonialsData.length) % testimonialsData.length);
  };

  const handleNextTestimonial = () => {
    setCurrentTestimonialIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
  };

  return (
    <section className="testimonial-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="testimonial-content">
              <h2>{testimonialsData[currentTestimonialIndex].header}</h2>
              <blockquote>{testimonialsData[currentTestimonialIndex].quote}</blockquote>
            </div>
            <div className="testimonial-navigation">
              <button onClick={handlePrevTestimonial}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708z"/>
                </svg>
              </button>
              <button onClick={handleNextTestimonial}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-90deg-right" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M14.854 4.854a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5v8a.5.5 0 0 0 1 0v-8A1.5 1.5 0 0 1 3.5 5h9.793l-3.147 3.146a.5.5 0 0 0 .708.708z"/>
                </svg>
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <img src={testimonialsData[currentTestimonialIndex].imageUrl} alt="Testimonial" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
