import React from 'react';
import { Link } from 'react-router-dom';
import './css/apply.css';

export const jobs = [
  { id: '6467898765342', title: 'Pre-degree Lecturer in Mass Communications', status: 'Open' },
  { id: '87609876534323', title: 'Pre-degree Lecturer in Business Administration', status: 'Open' },
  { id: '3093578986743', title: 'Pre-degree Lecturer in Law', status: 'Open' },
];

const Jobs = () => (
  <div className="Jobs">
    <div className="hero-section">
      <h1>Available Job Role(s)</h1>
    </div>
    <div className="Job-Column">
      {jobs.map(job => (
        <div key={job.id} className="job-item">
          <h2>{job.title}</h2>
          <p>Status: {job.status}</p>
          <Link to={`/career/jobs/${job.id}`}>View Job</Link>
        </div>
      ))}
    </div>
  </div>
);

export default Jobs;
