import React from 'react';
import { Helmet } from 'react-helmet';
import percourse from '../assets/images/training-percourse.jpg';
import campushall from '../assets/images/leanstop.jpg';
import { Link } from 'react-router-dom';
import PayPerCourseCategory from '../usercomponents/PayPerCourseCategory';
import Meta from '../components/Meta';



const PayCourse = () => {
  const title = "Pay Per Course | Lecturemeet";
  const description = "Build a strong foundation for your academic and professional journey. Enroll in our Pre-Degree courses in Law, Business Administration, and Mass Communications.";

  return (
    <div className="container-mx-auto-p-4">
     <Helmet>
        <title>{title}</title>
        <Meta name="description" content={description} />
        <Meta name="keywords" content="Lecturemeet, Pre-Degree Programme, foundation courses, Law, Business Administration, Mass Communications, academic preparation, professional development, education" />
        <Meta name="author" content="Lecturemeet" />
        <Meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <Meta charSet="UTF-8" />
        <Meta name="robots" content="index, follow" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Lecturemeet",
              "url": "https://lecturemeet.com/Pre-degree",
              "logo": "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n"
            }
          `}
        </script>

        <Meta property="og:title" content="Pre-Degree Programme | Lecturemeet" />
        <Meta property="og:description" content={description} />
        <Meta property="og:image" content="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n" />
        <Meta property="og:url" content="https://lecturemeet.com/Pre-degree" />
        <Meta name="twitter:card" content="summary_large_image" />
        <Meta name="twitter:site" content="@LectureMeet" />
        <Meta name="twitter:creator" content="@LectureMeet" />
      </Helmet>

      <section class="paycourse-page-section">
  <div class="About-container">
    <div class="about-content">
      <h2>Empowering learners to achieve professional goals</h2>
      <p> Take control of YOUR professional development, offering a tailored and efficient way to gain valuable skills and knowledge in a wide range of fields.</p>
      <Link to="/Login" style={{ color: 'yellow' }}> Get Started  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
    <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
  </svg>
</Link>
</div>
  </div>
</section>



<section className="payperprg">
    <div className="container">
      <div className="row">        
      <div className="col-md-6 pmsect-09">
          <img src={percourse} alt="Our Pay per course Program" className="img-fluid" />
            </div>
        <div className="col-md-6 pmsect-09">
          <br />
          <br />
          <br />
          <h1 style={{ textAlign: 'left', color: 'blue' }}>A Modern Approach to Learning</h1>
          <p>Our pay per course offers a modern, flexible, and cost-effective approach to learning compared to traditional models.</p>
         
        </div>

      </div>
    </div>
  </section>


  <section className="prog-ram-sect">
    <div className="container">
      <div className="row">
        <div className='prede'>
        <h2>Why Choose Pay-Per-Courses</h2>
        <p>Pay Per Course option is ideal for professionals  <br />
          looking to advance their careers in a specific field</p>
        </div>
        <div className="col-md-3 hkpime">
          <h3>Affordable</h3>
          <p>Manage your learning budget by selecting individual courses instead of a comprehensive program.</p>
        </div>
        <div className="col-md-3 hkpime">
          <h3>Convenient</h3>
          <p>Learn at your own pace and on your schedule, when and how you want.</p>
        </div>
        <div className="col-md-3 hkpime">
          <h3>Flexible Learning</h3>
          <p>Choose and pay only for the courses you need, allowing for a customized learning experience.</p>
        </div>
        <div className="col-md-3 hkpime">
          <h3>Certification</h3>
          <p>Not just the certificate, but you get to apply the knowledge and skills gained directly to your professional life.</p>
        </div>
      </div>
    </div>
  </section>

  <section className="prog-ram-sect">
  <div className="container">
    <div className="row">
      <div className="col-md-6 pmsect-09">
        <h2>Reaching development goals</h2>
        <p>Empowering learners to achieve professional goals and Closing the gap 
          in development students with a comprehensive understanding 
          of key concepts and principles in several fields</p>


          <h3>Made for everyone</h3>
        <p>Lecturemeet understands that each learner's journey is unique. 
          We provide tailored learning paths that align with individual career goals, 
          allowing learners to choose courses that best suit their professional needs.</p>
          <Link to="https://pay-per-course.lecturemeet.com" className="btn btn-primary">Get Started Now</Link>
            
          
      </div>
      <div className="col-md-6 pmsect-09">
        <img src={campushall} alt="Our Programmes" className="img-fluid" />
        <h3>Diverse range of courses in various fields</h3>
        <p>Whether you are a seasoned professional or a student starting your career journey, 
          Lecturemeet is your partner in lifelong learning and professional development.</p>
          <Link to="https://pay-per-course.lecturemeet.com" className="btn btn-primary">Get Started Now</Link>
            
      </div>
    </div>
    </div>
</section>


<section>
<PayPerCourseCategory />
</section>

    </div>
  );
};

export default PayCourse;
