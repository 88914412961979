import React, { useState } from 'react';

const AboutFAQSection = () => {
  const faqs = [
    { question: 'Who is eligible to apply for the scholarship?', answer: 'The scholarship is open to Nigerian citizens who have completed secondary school education in Nigeria' },
    { question: 'How are scholarship recipients selected?', answer: 'Recipients are selected based on academic performance, financial need, extracurricular activities, and the quality of their essay or personal statement.' },
    { question: 'Can I apply for the scholarship if I am already enrolled in a Pre-degree program ?', answer: ' No, the scholarship is only available to students who have been admitted to a Pre-degree program but have not yet commenced their studies and paid fees.' },
    { question: 'How can I donate to the Scholarship Fund ?', answer: 'You can donate online through our website or contact us for alternative donation methods.' },
    { question: 'How will my donation be used ?', answer: 'Donations to the Scholarship Fund will be used to cover tuition fees, learning materials and other educational expenses for scholarship recipients.' },
    { question: 'What documents do I need to submit with my application ?', answer: 'You will need to submit proof of admission to a Pre-degree program,  a personal statement or essay on why you need the scholarship and course of study, proof of financial need, and any other documents requested in the application form.' },
    
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="about-faqs">
      <div className="container">
        <h2>FAQ's About Our Scholarhips</h2>
        <div className="accordion">
          {faqs.map((faq, index) => (
            <div key={index} className={`accordion-item ${activeIndex === index ? 'active' : ''}`}>
              <div className="accordion-title" onClick={() => toggleAccordion(index)}>
                {faq.question}
                <span className="icon">{activeIndex === index ? '-' : '+'}</span>
              </div>
              <div className="accordion-content">
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutFAQSection;
