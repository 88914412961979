import React from 'react';
import { Helmet } from 'react-helmet';
import growth from '../assets/images/Founder.jpg';
import mission from '../assets/images/OurMission.jpg';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Aboutfaqs from '../usercomponents/Aboutfaqs';
import Meta from '../components/Meta';


const AboutPage = () => {
  const navigate = useNavigate();

  const handleOptionClick = (redirectUrl) => {
      if (redirectUrl) {
          navigate(redirectUrl);
      }
  };

  return (
    <div className="About-container container-mx-auto-p-4">
      <Helmet>
        <title>About | Lecturemeet</title>
        <Meta name="description" content="Lecturemeet is a leading online learning platform dedicated to fostering individual career growth and enhancing business team development." />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Lecturemeet",
              "url": "https://lecturemeet.com/About",
              "logo": "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n"
            }
          `}
        </script>
        <Meta property="og:title" content="About - Lectuemeet | Professional Certification" />
        <Meta property="og:description" content="Lecturemeet is a leading online learning platform dedicated to fostering individual career growth and enhancing business team development." />
        <Meta property="og:image" content="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n" />
        <Meta property="og:url" content="https://lecturemeet.com/About" />
        <Meta name="twitter:card" content="summary_large_image" />
        <Meta name="twitter:site" content="@LectureMeet" />
        <Meta name="twitter:creator" content="@LectureMeet" />
      </Helmet>

      <section class="about-background-section">
  <div class="About-container">
    <div class="about-content">
      <h2>Redefining growth from anywhere</h2>
      <p>Powering a generation of learning beyond borders and upskilling productivity</p>
    </div>
  </div>
</section>


      <section className="about-us">
    <div className="container">
      <div className="row">
        <div className="col-md-6 about-lm">
          <h2>Our Story</h2>
        </div>
        <div className="col-md-6 about-lm">
          <div>
            <h2>Building t<span className='text-black'>he process</span></h2>
            <p>Lecturemeet began with a simple yet powerful vision and founded on the belief that education should be a lifelong pursuit, Lecturemeet diverse range of courses and programs is designed to meet the evolving needs of learners from all walks of life. </p>
          </div>
        </div>
        <div  className='col-md-6 about-lm'>
        <p>Born from a visionary quest to democratize education Lecturemeet was developed to make learning accessible to all, breaking the shackles of traditional barriers. Our journey began with a simple yet powerful vision. We started with a dream to democratize education, and today, we stand as a beacon of limitless learning possibilities without borders. The impact on individuals, organizations, and communities speaks volumes about the collective power of knowledge.</p>
        </div>
      </div>
    </div>
  </section>



  <section className="our-Mission">
    <div className="growth-mission-container">
      <div className="row">
        <div className="col-md-6 goalsLm">
          <img src={mission} alt="Mission " className="img-fluid" />
        </div>
        <div className="col-md-6 goalsLm">
          <div className="mission-text">
            <h2>Our Mission</h2>
            <p>The evolution of LectureMeet is not just a story of courses; it's a narrative of empowerment, innovation, and breaking through borders. We've embraced technology to disrupt traditional learning, ensuring that every learner, regardless of location or circumstance, can access quality education.</p>
          </div>
        </div>
      </div>
    </div>
  </section>



  <section className="our-Mission growthpath">
    <div className="growth-mission-container">
      <div className="row">       
        <div className="col-md-6 goalsLm">
          <div className="mission-text">
            <h2>Our Growing Path</h2>
            <p>We believe that education should be accessible to all, regardless of location or circumstance. Our courses and programmes are  taught by industry professionals, ensuring that learners receive the best learning experience and practical insights. Together, we are reshaping the landscape of education, creating a world where learning knows no bounds.</p>
          </div>
        </div>
        <div className="col-md-6 goalsLm">
          <img src={growth} alt="Our Growth path" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>




<section className="what-to-learn">
    <div className="container">
      <div className="row">
        <div className="col-md-6 learntoday">
          <h1>What Are You Learning With Us Today?</h1>
          <p>Access programs in Corporate Governance, Risk Management, AML/CFT, Business Marketing, legal, HR, compliance, resource management, and product development fields and more</p>
          <Link to="/Programme" className="btn todayforYou">Explore Programmes</Link>
            
        </div>
      </div>
      <div className="Aboutlearn learn-right">
                        <h3>Find your area of interest:</h3>
                        <select onChange={(e) => handleOptionClick(e.target.value)}>
                        <option value="/Legal-and-compliance">Legal & Compliance</option>
                            <option value="/Corporate-and-banking-sector">Corporate & Finance</option>
                            <option value="/Human-resource-and-product-development-studies">Human Resource Management</option>
                            <option value="/Product-Management">Products Management</option>
                            <option value="/Pre-degree">Pre-Degree Programme</option>
                            <option value="/Pay-per-course">Pay pay course</option>
                        </select>
                    </div>
    </div>
  </section>




<section>
  <Aboutfaqs />
</section>




    </div>
  );
};

export default AboutPage;
