import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/BlogUpdates.css'; 
import foretams from '../blog/Assets/imgs/foretams.jpg';
import learn from '../blog/Assets/imgs/learn.jpg';
import withoutborders from '../blog/Assets/imgs/withoutborders.jpg';
import neverstoplearning from '../blog/Assets/imgs/neverstoplearning.jpg';
import compliance from '../blog/Assets/imgs/compliance.jpg';
import employee from '../blog/Assets/imgs/employee-train.jpg';
import time from '../blog/Assets/imgs/time.jpg';
import people from '../blog/Assets/imgs/people.jpg';
import Good from '../blog/Assets/imgs/Good.jpg';
import fit from '../blog/Assets/imgs/fit.jpg';
import regcomp from '../blog/Assets/imgs/regcomp.jpg';



  
  
  


const BlogUpdates = () => {
  const blogPosts = [
    { id: 1, imageUrl: regcomp, title: 'Working in the financial sector as a compliance officer: What you need to know and how to navigate challenges', link: '/blog/Working-in-the-financial-sector-as-a-compliance-officer' },
    { id: 2, imageUrl: compliance, title: '6 Job Roles in Compliance Certifications: Benefits, Roles, and Pay Grades', link: '/blog/compliance-certifications-5-job-roles-and-benefits' },
    { id: 3, imageUrl: foretams, title: 'Maximizing your online learning experience: Tips for students', link: '/blog/Maximizing-Your-Learning-Experience-Tips-for-Online-Students' },
    { id: 4, imageUrl: learn, title: 'The future of professional development: how online courses are changing the game', link: '/blog/The-future-of-professional-development' },
    { id: 5, imageUrl: withoutborders, title: 'Balancing work and study: time management tips for busy professionals', link: '/blog/Balancing-work-and-study' },
    { id: 6, imageUrl: neverstoplearning, title: 'Career advancement through certification with Lecturemeet: why it matters and how to get started', link: '/blog/Career-advancement-through-certification-courses-on-lecturemeet' },
    { id: 7, imageUrl: employee, title: 'Why should a business organisation train employees : 5 reasons', link: '/blog/employee-training-for-teams-organsaitions' },
  ];


  const blogPosts2 = [
    { id: 1, imageUrl: time, title: 'Staying productive in a work environment', link: '/blog/Staying-productive-in-a-work-environment' },
    { id: 2, imageUrl: people, title: 'Learning how to navigate job markets', link: '/blog/Learning-how-to-navigate-job-markets' },
    { id: 3, imageUrl: fit, title: 'Finding the role that fits', link: '/blog/Articles' },
    { id: 4, imageUrl: Good, title: 'Becoming a good fit for an organisation: a must do guide', link: '/blog/Articles' },
  ];

  
  return (
    <div className="UpdatesBlog-container">
      <header className="UpdatesBlog-header">
        <h1>Guide</h1>
      </header>
      <div className="UpdatesBlog">
        {blogPosts.map((post) => (
          <Link to={post.link} key={post.id} className="UpdatesBlog-item">
            <img src={post.imageUrl} alt={post.title} className="UpdatesBlog-image" />
            <h2 className="UpdatesBlog-title">{post.title}</h2>
          </Link>
        ))}
      </div>



      <div className="HeroBlog-container">
      <div className="HeroBlog-section">
        <h1>Find the right course. Empower teams</h1>
        <p className='text-center'>Courses are updated reguarly and modules delivered by expert instructors. <br />
            Join Lecturemeet today in learning 
            and improving beyond borders your career golas
        </p>
        <Link to="https://blog.lecturemeet.com" target='_blank' className="hero-button">Explore Topics</Link>
      </div>
      </div>

      <header className="UpdatesBlog-header">
        <h1>Development Goals & Skills</h1>
      </header>
      <div className="UpdatesBlog">
        {blogPosts2.map((post) => (
          <Link to={post.link} key={post.id + '-pr'} className="UpdatesBlog-item">
            <img src={post.imageUrl} alt={post.title} className="UpdatesBlog-image" />
            <h2 className="UpdatesBlog-title">{post.title}</h2>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogUpdates;
