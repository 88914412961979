import React, { useState } from 'react';

const AboutFAQSection = () => {
  const faqs = [
    { question: 'What types of certificates does Lecturemeet offer ?', answer: 'Lecturemeet offers various types of certificates, including Course Completion Certificates, Program Certificates, and Specialized Certifications. Each type caters to different levels of learning and professional development.' },
    { question: 'Are Lecturemeet certificates recognized ?', answer: 'Yes, Lecturemeet aims to align its courses and certifications with international standards and best practices, ensuring global recognition of its certificates.' },
    { question: 'How can I enroll in a course to obtain a certificate ?', answer: 'You can enroll in a course by visiting the Lecturemeet platform, browsing through the available courses, and registering for the ones that suit your learning goals.' },
    { question: 'What do I need to do to earn a certificate from Lecturemeet ?', answer: 'To earn a certificate, you must complete all required modules and assessments for a course or program.' },
    { question: 'How will I receive my certificate  ?', answer: 'Certificates are issued digitally upon successful completion of the course immediately. You can download your certificate from your Lecturemeet account.' },
    { question: 'Verification and Authenticity', answer: 'To verify the authenticity of a certificate included on the certificate is verification id or a certificate id. To verifiy a certificate can be done through the Lecturemeet platforms verification feature.  <a href="https://certificate.lecturemeet.com" style="color: #007bff; text-decoration: none;">here</a>.' },
    { question: 'Is there a fee for certificate verification ?', answer: 'No, verification of certificates is typically free of charge' },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="about-faqs">
      <div className="container">
        <h2>FAQ's on our Certificate</h2>
        <div className="accordion">
          {faqs.map((faq, index) => (
            <div key={index} className={`accordion-item ${activeIndex === index ? 'active' : ''}`}>
              <div className="accordion-title" onClick={() => toggleAccordion(index)}>
                {faq.question}
                <span className="icon">{activeIndex === index ? '-' : '+'}</span>
              </div>
              <div className="accordion-content">
                <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutFAQSection;
