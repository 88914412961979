import React, { useState } from 'react';
import '../Pre-degree/css/Notification.css';

function NotificationAlert() {
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="NotificationAlert">
      {showPopup && (
        <div className="NotificationAlert-popup">
          <div className="NotificationAlert-popup-content">
            <span className="NotificationAlert-close-btn" onClick={handleClosePopup}>&times;</span>
            <h2>Disclaimer Notice:</h2>
            <p>The Pre-Degree Program offered by Lecturemeet is not a degree course, and no degree certificate will be awarded upon completion. This program is a foundational crash course designed to integrate regular university learning modules and curricula, providing essential knowledge for further academic pursuits. While the certificate issued upon completion may be used to gain entry into partner universities or to seek internship placements and work opportunities, it does not equate to a university degree.</p>
           <p><strong>Signed</strong> 
           <br />Team Lecturemeet</p>
           <p>Thank you.</p>
            
          </div>
        </div>
      )}
    </div>
  );
}

export default NotificationAlert;
