import React from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cardholder from '../usercomponents/Cardholder';
import ProgrammeHeroEffect from '../usercomponents/ProgrammeHeroEffect';
import learninonline from '../assets/images/learninonline.jpg';
import Meta from '../components/Meta';




function Home() {
  return (
    <div className="container-home">
      <Helmet>
        <title>Programmes | Lectuemeet </title>
        <Meta name="description" content="Explore Lecturemeet's wide range of professional learning programmes. Choose from courses in Corporate and Banking, Legal/HR/Compliance, Resource Management, Product Development, and more." />
<Meta name="keywords" content="Lecturemeet, programmes, professional learning, career advancement, education, corporate and banking, legal, HR, compliance, resource management, product development" />
<Meta name="author" content="Lecturemeet" />
<Meta name="viewport" content="width=device-width, initial-scale=1.0" />
<Meta charset="UTF-8" />
<Meta name="robots" content="index, follow" />

<script type="application/ld+json">
    {`
        {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Lecturemeet",
        "url": "https://lecturemeet.com/Programme",
        "logo": "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n"
        }
    `}
</script>

<Meta property="og:title" content="Programmes | Lecturemeet" />
<Meta property="og:description" content="Explore Lecturemeet's wide range of professional learning programmes. Choose from courses in Corporate and Banking, Legal/HR/Compliance, Resource Management, Product Development, and more." />
<Meta property="og:image" content="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n" />
<Meta property="og:url" content="https://lecturemeet.com/Programme" />
<Meta name="twitter:card" content="summary_large_image" />
<Meta name="twitter:site" content="@LectureMeet" />
        <Meta name="twitter:creator" content="@LectureMeet" />

      </Helmet>
      
      

      <section className="mt-8">
        <ProgrammeHeroEffect />
      </section>


      <section className="Cardholder-section">
        <Cardholder />
      </section>


      <section className="prog-ram-sect">
    <div className="container">
      <div className="hero-image">
        <img src="https://media.istockphoto.com/id/1468574063/photo/portrait-of-a-female-soccer-team-celebrating.jpg?s=612x612&w=0&k=20&c=tGWjDkg5Ehi0_x1ZdltXfIFQ6AFyuuxbpE2z7XO4NBU=" alt="Hero" />
      </div>
      <div className="row">
        <div className="col-md-3 hkpime">
          <h3>Flexibility</h3>
          <p>Self paced learning allowing you to study at your own convenience and pace accommodating busy schedules and diverse learning preferences.</p>
        </div>
        <div className="col-md-3 hkpime">
          <h3>Career Switching</h3>
          <p>Looking to switch careers or enter new industries ? Lecturemeet provides specialized programs and courses that equip with the necessary knowledge and skills. </p>
        </div>
        <div className="col-md-3 hkpime">
          <h3>Upskilling</h3>
          <p>Lecturemeet's focus on professional certification and skill development contributes to both personal growth and business success. </p>
        </div>
        <div className="col-md-3 hkpime">
          <h3>Certification</h3>
          <p>Validate skills and knowledge, serving as credentials that  enhance resumes and career opportunities and commitment to professional development.</p>
        </div>
      </div>
    </div>
  </section>




  <section className="prog-190">
    <div className="container">
      <div className="row">
        <div className="col-md-6 pmsect-09">
          <h2>Build The future</h2>
          <p>LectureMeet is designed for a global target audience looking 
            to build and grow skills beyond employment while earning 
            recognized certification. We have reimagined what a modern 
            learning experience looks like, torn down the walls of the 
            traditional learning model and designed a modern learning 
            experience 100% online tha is unique and different from others.</p>
         
            <h3>Tech driven</h3>
          <p>By leveraging technology, we deliver world-class education, 
            at a fraction of the cost of traditional learning programmes. 
            <br />
            Our programs are not only thought by industry experts but designed 
            to develop a practical skill set you will use to get ahead in your 
            career.  access courses from anywhere, breaking down geographical 
            barriers to education.</p>

        </div>
        <div className="col-md-6 pmsect-09">
          <img src={learninonline} alt="Our Programmes" className="img-fluid" />
            </div>
      </div>
    </div>
  </section>









    </div>
  );
}

export default Home;
