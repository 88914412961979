import React from 'react';

const TestimonialsSection = () => {
  return (
    <section className="testimonials-section">
        <div className='globalheader'>
        <h2>Our Global Impact</h2>
        </div>
      <div className="testimonial">        
        <p className="quote">"Lecturemeet has been a great resource for expanding my skillset. The courses are well structured and I appreciate the flexibility of being able to learn at my own pace. The only downside is that I wish there were more courses available."</p>
        <p className="author">- Sarah Theophilus</p>
      </div>
      <div className="testimonial">
        <p className="quote">"Lecturemeet is a fantastic platform for professional development. The courses are diverse, and the content is up-to-date. I particularly appreciate the certification feature, as it adds value to my resume."</p>
        <p className="author">- David Samuel</p>
      </div>
      <div className="testimonial">
        <p className="quote">"Enrolled in the Human Resource Management course on Lecturemeet and it has
        been an intensive and educating journey. It has impacted and shapped my work and career goals.."</p>
        <p className="author">-Cheta Udo Modupe</p>
      </div>
      <div className="testimonial">
        <p className="quote">"Our team's performance and collaboration have improved significantly"</p>
        <p className="author">- Paye Epang</p>
      </div>
    </section>
  );
};

export default TestimonialsSection;
