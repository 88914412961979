import React, { useEffect } from 'react';
import Typed from 'typed.js';

const HeroSection = () => {
  useEffect(() => {
    const strings = [
      'Strategic Resource Planning and business development.',
      'Project Management and Resource Allocation.',
      'HR best practices and legal frameworks for businesses..',
      'Compliance, Anti-Money Laundering (AML/CFT).',
      'Due Process and Corporate Governance',
      'Corporate governance, Mergers and Acquisition',
      'General Compliance, Anti-Money Laundering (AML/CFT)',
      'Due Process and Corporate Governance',
      'Pre-degree foundational studies in law, business, or management courses',
    ];

    const options = {
      strings: strings,
      typeSpeed: 50,
      backSpeed: 30,
      loop: true,
      backDelay: 2000,
    };

    const typed = new Typed('.typing-element', options);

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="hero-section">
      <h1><span>Learning </span> <br />Beyond <span>Borders</span></h1>
      <p>
        <span className="typing-element"></span>
      </p>
     
    </div>
  );
};

export default HeroSection;
