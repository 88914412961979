import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import campushall from '../assets/images/campushall.avif';
import '../assets/css/Pre-degree.css';
import { Link } from 'react-router-dom';
import Buildlearn from '../assets/images/online.jpg';
import LearningModelLaw from './Module/LearningModel-Law';
import Notification from '../components/Notification';



const LawPre = ({ imageUrl, header, text }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/Admission-for-pre-degree'); 
  };



    const handleClickPrograms = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
  return (
    <div className="container-mx-auto-p-4">
      <Helmet>
        <title>Law - Pre-Degree Programme | Lecturemeet </title>
        <meta name="description" content="Prepare for your academic journey in law. Enroll in our comprehensive pre-degree course to build a solid foundation in law." />
<meta name="keywords" content="Lecturemeet, Law, Pre-Degree Programme, academic preparation, professional development, education, legal studies" />
<meta name="author" content="Lecturemeet" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta charset="UTF-8" />
<meta name="robots" content="index, follow" />

<script type="application/ld+json">
    {`
        {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Lecturemeet",
        "url": "https://lecturemeet.com/Pre-degree/Law",
        "logo": "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n"
        }
    `}
</script>

<meta property="og:title" content="Law - Pre-Degree Programme | Lecturemeet" />
<meta property="og:description" content="Prepare for your academic journey in law. Enroll in our comprehensive pre-degree course to build a solid foundation in law." />
<meta property="og:image" content="https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/colcwyhfjpkv2srvhy5n" />
<meta property="og:url" content="https://lecturemeet.com/Pre-degree/Law" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:site" content="@LectureMeet" />
        <meta name="twitter:creator" content="@LectureMeet" />

      </Helmet>



      <div className="pre-degree-program-course-hero">
            <h1 className="pre-degree-program-course-header">Pre-Degeee Course Application 
            Commences <br />in November, 2024</h1>
            <p className="pre-degree-program-course-paragraph">
                Get ready to embark on an exciting learning journey with LectureMeet! Our pre-degree programs are designed 
                to give you the foundational knowledge and skills you need to excel in your chosen field. Join us and 
                experience the joy of learning like never before.
            </p>
            <a href="https://pre-degree.lecturemeet.com/onboard" className="pre-degree-program-course-button">
                Notify Me
            </a>
        </div>


        
      <section className="prog-mass-comm">
      <div className="container">
        <div className="header-content">
          <h1>Diploma in Law</h1>
          <p>Join other learners to embark on a learning journey.</p>
          <button onClick={handleClick}>See Requirements</button>
        </div>
      </div>
    </section>



<br />

<section className="scroll-to-section">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <a href="#about-program" className="scroll-to-link" onClick={() => handleClickPrograms('about-program')}>About Program</a>
          </div>
          <div className="col-md-3">
            <a href="#admission" className="scroll-to-link" onClick={() => handleClickPrograms('admission')}>Admission & Requirements</a>
          </div>
          <div className="col-md-3">
            <a href="#fees" className="scroll-to-link" onClick={() => handleClickPrograms('fees')}>Fees</a>
          </div>
          <div className="col-md-3">
            <a href="#learning-model" className="scroll-to-link" onClick={() => handleClickPrograms('learning-model')}>Learning Model</a>
          </div>
          <div className="col-md-3">
            <a href="#scholarship" className="scroll-to-link" onClick={() => handleClickPrograms('scholarship')}>Scholarship</a>
          </div>
          <div className="col-md-3">
            <a href="#internship" className="scroll-to-link" onClick={() => handleClickPrograms('internship')}>Internship Opportunities</a>
          </div>
          <div className="col-md-3">
            <a href="#remote-job" className="scroll-to-link" onClick={() => handleClickPrograms('remote-jobs')}>Career </a>
          </div>
          <div className="col-md-3">
            <a href="#benefits" className="scroll-to-link" onClick={() => handleClickPrograms('benefits')}>Benefits</a>
          </div>
        </div>
      </div>
    </section>



    <section className="prog-190" id='about-program'>
    <div className="container">
      <div className="row">        
      <div className="col-md-6 pmsect-09">
          <img src={campushall} alt="About the Mass Commmunication Programmes" className="img-fluid" />
            </div>
        <div className="col-md-6 pmsect-09">
          <br />
          <br />
          <br />
          <h1 style={{ textAlign: 'left', color: 'blue' }}>Diploma in Law</h1>
          <p>Designed to offer a comprehensive introduction to 
            various areas of law, equipping students with essential 
            knowledge and skills required for success in legal studies 
            or legal professions. 
            
            <br />
            <br />
            The program covers fundamental legal 
            principles, methods, and areas of law, providing a solid grounding 
            for further academic pursuit in law or initial roles in the legal field. </p>
         
        </div>

      </div>
    </div>
  </section>







 {/* Admission & Requirements Section */}
 <section className="admission-requirements container-text" id="admission">
                <div className="container">
                    <h2>Admission & Requirements</h2>
                    <p>Find out the entry requirements and application process for Diploam or Pre-degree program in Law at Lecturemeet, you need;</p>
                    <ul>
                        <li>High School Diploma or equivalent</li>
                        <li>Must have completed secondary education (SSCE/WAEC/NECO) with a minimum of five credits, including English Language and Mathematics.</li>
                        <li>Must be a Nigerian or studied in any African country</li>
                        <li>Must be at least 16 years old at the time of application</li>
                        <li>Pass the entrance assessment by Lecturemeet</li>
                    </ul>
                </div>
            </section>

            {/* Fees Section */}
            <section className="fees container-text" id="fees">
                <div className="container">
                    <h2>Program Fees</h2>
                    <br />
                    <p>Duration: 3 months</p>
                   <ul>
                        <li>Non-Refundable Application Fee: ₦15,000</li>
                        <li>Tuition Fee: ₦185,000 <s className='text-danger'>₦245,000</s> (With learning kits and physical certificate delivery)</li>
                        <li>Tuition Fee: 85,000 <s className='text-danger'>₦130,000</s> (Without learning kits). Materials are all digital </li>
                        <br />
                        <p>Any of the fees covers academic study for the duration inclusive of the course materials</p>
                       </ul>
                   
                </div>
            </section>

        

            {/* Learning Model Section */}
            <section className="learning-model" id="learning-model">
<LearningModelLaw />
            </section>


            {/* Scholarship Section */}
            <section className="scholarship container-text" id="scholarship">
            <div className="container">
                <h2>Scholarship Opportunities</h2>
                <p>Offering scholarships for our Law Pre-degree program, covers tuition and learning materials for a 3-month duration with a diploma upon completion. Scholarships are currently open for only eligible applicants and are awarded based on merit and financial need.</p>
                <ul>
                    <li>Merit-based Scholarships</li>
                    <li>Need-based Financial Aid</li>
                    <li>Special Grants from support partners</li>
                </ul>
                <Link 
                    to="/Scholarship" 
                    className="btn todayforYou" 
                    style={{
                        display: 'inline-block',
                        padding: '0.5rem 1rem',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: '#ffffff',
                        backgroundColor: '#007bff',
                        border: 'none',
                        borderRadius: '0.25rem',
                        textDecoration: 'none',
                        textAlign: 'left',
                        marginTop: '1rem',
                        transition: 'background-color 0.3s'
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
                >
                    Explore Scholarship
                </Link>
            </div>
        </section>

            {/* Internship Opportunities Section */}
            <section className="internship container-text" id="internship">
                <div className="container">
                    <h2>Internship Opportunities</h2>
                    <p>Learn about internship placements and career support provided during and after the program.</p>
                    <ul>
                        <li>Industry Partnerships</li>
                        <li>Work Experience Opportunities</li>
                        <li>Career Counseling</li>
                    </ul>
                </div>
            </section>

            {/* Job Apply Section */}
            <section className="paypercourse-section" id="remote-job">
  <div className="paypercourse-container">
    <div className="per-course-left">
      <h2>Explore Career Options</h2>
      <p>Career options with a diploma degree certificate in law
        <br />
        A diploma in law provides a solid foundation for various legal and administrative roles, offering flexibility and a broad range of career options across different sectors.
  </p>
      <div className="per-course-grid">
        <div className="per-course-item">Court Clerk</div>
        <div className="per-course-item">Legal Secretary</div>
        <div className="per-course-item"> Legal Assistant/Paralegal</div>
        <div className="per-course-item">Legal Researcher</div>
        <div className="per-course-item">On Air Personnel (OAP)</div>
        <div className="per-course-item">Media Marketing Coordinator</div>
      </div>
    </div>
    <div className="per-course-right">
      <img src={Buildlearn} alt="Career options in Mass Communications" />
    </div>
  </div>
</section>


            {/* Benefits Section */}
            <section className="benefits container-text" id="benefits">
                <div className="container">
                    <h2>Program Benefits</h2>
                    <p>Discover the benefits of enrolling in the Mass Communications pre-degree program.</p>
                    <ul>
                        <li>Internship Opportunity</li>
                        <li>Partner direct entry</li>
                        <li>Networking Opportunities</li>
                        <li>Career Advancement</li>
                    </ul>
                </div>
            </section>






            <Notification />

    </div>
  );
};

export default LawPre;
