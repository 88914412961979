import React from 'react';
import onlineImage from '../assets/images/online.jpg';
import learningImage from '../assets/images/learning.jpg';
import { Link } from 'react-router-dom';




const WhyLMSection = () => {
  return (
    <section className="why-lm">
      <div className="left-side">
        <div className="image-container onlineImage">
          <img src={onlineImage} alt="Online" />
        </div>
        <div className='text-align-left'>
        <h2>Endless Learning Possibilities <br />Beyond Borders</h2>
        <p>
        Take the first step towards transforming your life. With our expert-led courses, flexible learning options, and global community, Lecturemeet is the perfect partner on your learning journey.
        </p>
        </div>
        <ul className="whyus-columns">
          <li className='whyhover'><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-tags-fill" viewBox="0 0 16 16">
  <path d="M2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586zm3.5 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
  <path d="M1.293 7.793A1 1 0 0 1 1 7.086V2a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l.043-.043z"/>
</svg> 
            <h3>Affordable learning </h3>
            <p>Whether you're looking to enhance your skills in corporate governance, risk management, compliance, or product development, Lecturemeet has you covered.</p>
          </li>
          <br />
          
          <li className='whyhover'><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-globe-asia-australia" viewBox="0 0 16 16">
  <path d="m10.495 6.92 1.278-.619a.483.483 0 0 0 .126-.782c-.252-.244-.682-.139-.932.107-.23.226-.513.373-.816.53l-.102.054c-.338.178-.264.626.1.736a.48.48 0 0 0 .346-.027ZM7.741 9.808V9.78a.413.413 0 1 1 .783.183l-.22.443a.6.6 0 0 1-.12.167l-.193.185a.36.36 0 1 1-.5-.516l.112-.108a.45.45 0 0 0 .138-.326M5.672 12.5l.482.233A.386.386 0 1 0 6.32 12h-.416a.7.7 0 0 1-.419-.139l-.277-.206a.302.302 0 1 0-.298.52z"/>
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M1.612 10.867l.756-1.288a1 1 0 0 1 1.545-.225l1.074 1.005a.986.986 0 0 0 1.36-.011l.038-.037a.88.88 0 0 0 .26-.755c-.075-.548.37-1.033.92-1.099.728-.086 1.587-.324 1.728-.957.086-.386-.114-.83-.361-1.2-.207-.312 0-.8.374-.8.123 0 .24-.055.318-.15l.393-.474c.196-.237.491-.368.797-.403.554-.064 1.407-.277 1.583-.973.098-.391-.192-.634-.484-.88-.254-.212-.51-.426-.515-.741a7 7 0 0 1 3.425 7.692 1 1 0 0 0-.087-.063l-.316-.204a1 1 0 0 0-.977-.06l-.169.082a1 1 0 0 1-.741.051l-1.021-.329A1 1 0 0 0 11.205 9h-.165a1 1 0 0 0-.945.674l-.172.499a1 1 0 0 1-.404.514l-.802.518a1 1 0 0 0-.458.84v.455a1 1 0 0 0 1 1h.257a1 1 0 0 1 .542.16l.762.49a1 1 0 0 0 .283.126 7 7 0 0 1-9.49-3.409Z"/>
</svg>
            <h3>Learn at anytime from anywhere</h3>
            <p>Whether you prefer to study in the evenings, on weekends, or during your lunch break, Lecturemeet has the flexibility to accommodate your needs allowing you to learn at your own pace and on your own schedule.</p>
          </li>
          <br />

          <li className='whyhover'><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-bar-chart-steps" viewBox="0 0 16 16">
  <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z"/>
</svg>
            <h3>Redefine your opportunity</h3>
            <p>Whatever your goals may be, Lecturemeet is here to help you achieve them. Whether you want to advance in your career, or simply expand your horizons, Lecturemeet provides the tools and resources you need to succeed.</p>
          </li>
          <br />
          
          <li className='whyhover'><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-layers-fill" viewBox="0 0 16 16">
  <path d="M7.765 1.559a.5.5 0 0 1 .47 0l7.5 4a.5.5 0 0 1 0 .882l-7.5 4a.5.5 0 0 1-.47 0l-7.5-4a.5.5 0 0 1 0-.882z"/>
  <path d="m2.125 8.567-1.86.992a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882l-1.86-.992-5.17 2.756a1.5 1.5 0 0 1-1.41 0z"/>
</svg>
            <h3>Organisational productivity</h3>
            <p>Our corporate training programs are designed to help organizations of all sizes improve their performance and achieve their goals.</p>
          </li>
        </ul>
      </div>
      <div className="right-side">
        <img src={learningImage} alt="Learning" />
        <h1>Why learn at LectureMeet</h1>
        <p>
        With Lecturemeet, you can start your learning journey today and take the first step towards a brighter future. 
        </p>
        <Link to="/About" className="text-black bg-white btn btn-primary" style={{ textDecoration: 'none' }}>
  Learn More
</Link>
      </div>
    </section>
  );
};

export default WhyLMSection;
