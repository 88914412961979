import React, { useEffect } from 'react';
import Typed from 'typed.js';

const HeroSection = () => {
  useEffect(() => {
    const strings = [
      'Business strategy',
      'Product management',
      'Anti money laundering',
      'Corporate governance',
      'Compliance and Risk management',
    ];

    const options = {
      strings: strings,
      typeSpeed: 50,
      backSpeed: 30,
      loop: true,
      backDelay: 2000,
    };

    const typed = new Typed('.typing-element', options);

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="hero-section">
      <h1><span>Learning & Devel</span>opment <br />Beyond <span>Borders</span></h1>
      <h4>In</h4>
      <p>
        <span className="typing-element"></span>
      </p>
     
    </div>
  );
};

export default HeroSection;
