import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/BlogUpdates.css'; 
import foretams from '../blog/Assets/imgs/foretams.jpg';
import learn from '../blog/Assets/imgs/learn.jpg';
import withoutborders from '../blog/Assets/imgs/withoutborders.jpg';
import neverstoplearning from '../blog/Assets/imgs/neverstoplearning.jpg';

const BlogUpdates = () => {
  const blogPosts = [
    { id: 1, imageUrl: foretams, title: 'Maximizing your learning experience: tips for online students', link: '/blog/Maximizing-Your-Learning-Experience-Tips-for-Online-Students' },
    { id: 2, imageUrl: learn, title: 'The future of professional development: how online courses are changing the game', link: '/post2' },
    { id: 3, imageUrl: withoutborders, title: 'Balancing work and study: time management tips for busy professionals', link: '/post3' },
    { id: 4, imageUrl: neverstoplearning, title: 'Career advancement through certification with Lecturemeet: why it matters and how to get started', link: '/post4' },
  ];

  return (
    <div className="UpdatesBlog-container">
      <header className="UpdatesBlog-header">
        <h1>Articles</h1>
      </header>
      <div className="UpdatesBlog">
        {blogPosts.map((post) => (
          <Link to={post.link} key={post.id} className="UpdatesBlog-item">
            <img src={post.imageUrl} alt={post.title} className="UpdatesBlog-image" />
            <h2 className="UpdatesBlog-title">{post.title}</h2>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogUpdates;
