import React, { useState, useEffect } from 'react';

const PricingPage = () => {
    const [location, setLocation] = useState('Nigeria');
    const [teamSize, setTeamSize] = useState(1);
    const [convertedAmount, setConvertedAmount] = useState(0);

    useEffect(() => {
        // Example conversion rates
        const conversionRates = {
            Nigeria: 1,
            US: 0.0025, // 1 NGN to USD
            UK: 0.0018, // 1 NGN to GBP
            Canada: 0.0032, // 1 NGN to CAD
            Australia: 0.003, // 1 NGN to AUD
            SouthAfrica: 0.036, // 1 NGN to ZAR
            Kenya: 0.27, // 1 NGN to KES
            Ghana: 0.14, // 1 NGN to GHS
        };
        const departmentPrice = 35000;

        // Convert amount based on location and team size
        const rate = conversionRates[location] || 1; // Default to Nigeria if location not found
        setConvertedAmount(departmentPrice * rate * teamSize);
    }, [location, teamSize]);

    const handleTeamSizeChange = (e) => {
        setTeamSize(parseInt(e.target.value));
    };

    const handleLocationChange = (e) => {
        setLocation(e.target.value);
    };

    return (
        <div className="pricing-page">
            <h1>Calculate amount for teams</h1>
            <div>
                <label htmlFor="teamSize">Team Size:</label>
                <select id="teamSize" value={teamSize} onChange={handleTeamSizeChange}>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                    <option value={100}>100+</option>
                    <option value={200}>Up to 200 teams ? Contact us</option>
                </select>
            </div>
            <div>
                <label htmlFor="location">Location:</label>
                <select id="location" value={location} onChange={handleLocationChange}>
                <option value="Australia">Australia</option>
    <option value="Brazil">Brazil</option>
    <option value="Canada">Canada</option>
    <option value="Ghana">Ghana</option>
    <option value="Kenya">Kenya</option>
    <option value="Nigeria">Nigeria</option>
    <option value="SouthAfrica">South Africa</option>
    <option value="UK">United Kingdom</option>
    <option value="US">United States</option>
    <option value="Other"><strong style={{ fontWeight: 'bold' }}>Other Region</strong></option>
                </select>
            </div>
            <div>
                <h2>Total Amount: {location === 'Nigeria' ? '₦' : (location === 'US' ? '$' : (location === 'UK' ? '£' : (location === 'Canada' ? 'CA$' : (location === 'Australia' ? 'AU$' : (location === 'India' ? '₹' : (location === 'Japan' ? '¥' : (location === 'China' ? '¥' : (location === 'Germany' ? '€' : (location === 'France' ? '€' : (location === 'Brazil' ? 'R$' : (location === 'SouthAfrica' ? 'R' : (location === 'Kenya' ? 'KSh' : (location === 'Ghana' ? 'GH₵' : (location === 'Egypt' ? 'E£' : (location === 'UAE' ? 'AED' : (location === 'SaudiArabia' ? 'SR' : (location === 'Singapore' ? 'S$' : (location === 'Malaysia' ? 'RM' : 'Rp'))))))))))))))))))}{convertedAmount}</h2>
            </div>
        </div>
    );
};

export default PricingPage;
