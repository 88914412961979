import React from 'react';
import { Link, useParams } from 'react-router-dom';
import '../css/jobDescription.css';

const jobDescriptions = {
  '6467898765342': {
    title: 'Pre-degree Lecturer in Mass Communications',
    description: (
      <>
        <p>
        We are seeking a passionate and experienced Pre-degree Lecturer in Mass Communications to join our academic team. The ideal candidate will be responsible for delivering lectures, guiding students, and developing curriculum materials that align with the university standards.
        </p>
        <h3>Key Responsibilities:</h3>
        <ul>
        <li>Deliver engaging lectures and tutorials in Mass Communications.</li>
          <li>Develop and update curriculum materials for the Pre-degree program.</li>
          <li>Assess student performance through assignments, tests, and exams.</li>
          <li>Provide academic counseling and support to students.</li>
          <li>Conduct research and contribute to the academic community in the field of Mass Communications.</li>
        </ul>
      </>
    ),
    requirements: (
      <>
        <h3>Education and Experience:</h3>
        <ul>
          <li>Master’s degree in Mass Communications or a related field.</li>
          <li>Minimum of 3 years of teaching experience in an academic setting.</li>
          <li>Experience in curriculum development is an added advantage.</li>
        </ul>
        <h3>Skills and Competencies:</h3>
        <ul>
          <li>Strong knowledge of Mass Communications principles and practices.</li>
          <li>Excellent communication and presentation skills.</li>
          <li>Ability to engage and motivate students.</li>
        </ul>
        <h3>Additional Requirements:</h3>
        <ul>
          <li>Commitment to academic excellence and continuous learning.</li>
          <li>Ability to work independently and as part of a team.</li>
        </ul>
      </>
    ),
  },
  
  
  '87609876534323': {
    title: 'Pre-degree Lecturer in Business Administration',
    description: (
      <>
        <p>
         We are seeking a knowledgeable and experienced Pre-degree Lecturer in Business Administration to join our academic team. The successful candidate will be responsible for teaching, mentoring students, and developing course materials that meet academic standards.
        </p>
        <h3>Key Responsibilities:</h3>
        <ul>
          <li>Teach courses in Business Administration to Pre-degree students.</li>
          <li>Prepare and update course syllabi, lecture materials, and assignments.</li>
          <li>Evaluate student performance and provide constructive feedback.</li>
          <li>Guide students in understanding business concepts and practices.</li>
          <li>Participate in departmental meetings and contribute to program development.</li>
        </ul>
      </>
    ),
    requirements: (
      <>
        <h3>Education and Experience:</h3>
        <ul>
          <li>Master’s degree in Business Administration or a related field.</li>
          <li>At least 3 years of teaching experience in a similar role.</li>
          <li>Experience in course design and development is preferred.</li>
        </ul>
        <h3>Skills and Competencies:</h3>
        <ul>
          <li>Deep understanding of Business Administration theories and practices.</li>
          <li>Strong analytical and problem-solving skills.</li>
          <li>Excellent written and verbal communication skills.</li>
        </ul>
        <h3>Additional Requirements:</h3>
        <ul>
          <li>Dedication to student success and academic integrity.</li>
          <li>Ability to collaborate with colleagues and contribute to the academic environment.</li>
        </ul>
      </>
    ),
  },

  '3093578986743': {
    title: 'Pre-degree Lecturer in Law',
    description: (
      <>
       <p>
       We are looking for a highly qualified and motivated Pre-degree Lecturer in Law to join our faculty. The successful candidate will be responsible for delivering lectures, assessing students, and developing course materials that align with the Pre-degree program’s objectives.
        </p>
        <h3>Key Responsibilities:</h3>
        <ul>
          <li>Teach foundational law courses to Pre-degree students.</li>
          <li>Design and update course content to meet academic standards.</li>
          <li>Assess and grade student assignments, exams, and projects.</li>
          <li>Provide academic guidance and support to students.</li>
          <li>Engage in scholarly activities and contribute to the legal community.</li>
        </ul>
      </>
    ),
    requirements: (
      <>
        <h3>Education and Experience:</h3>
        <ul>
          <li>LLB degree from a recognized institution, with a Master’s degree in Law preferred.</li>
          <li>Minimum of 3 years of teaching experience in Law.</li>
          <li>Experience in curriculum development and legal research.</li>
        </ul>
        <h3>Skills and Competencies:</h3>
        <ul>
          <li>Strong knowledge of legal principles and practices.</li>
          <li>Excellent teaching and mentoring skills.</li>
          <li>Ability to communicate complex legal concepts clearly.</li>
        </ul>
        <h3>Additional Requirements:</h3>
        <ul>
          <li>Commitment to fostering a positive learning environment.</li>
          <li>Ability to work collaboratively with faculty members.</li>
        
      </ul>
    </>
    )
  },
};

const JobDescription = () => {
  const { id } = useParams();
  const job = jobDescriptions[id];

  if (!job) {
    return <div>Job not found</div>;
  }

  
  return (
    <div className="job-description-container">
      <div className="job-description">
        <h1>{job.title}</h1>
        <p>{job.description}</p>
        <h3>Requirements</h3>
        <p>{job.requirements}</p>
        <Link to={`/career/jobs/apply/${id}`}>Apply Now</Link>
      </div>
    </div>
  );
};


export default JobDescription;
