import React from 'react';
import techcabal from '../assets/images/techcabal.png';
import techcrunch from '../assets/images/techcrunch.jpg';
import technext from '../assets/images/technext.png';
import nairametrics from '../assets/images/nairametrics.png';

const FeaturedSection = () => {
  const handleRedirect = (url) => {
    window.open(url, '_blank');
  };

  return (
    <section className="featured-section">
      <h1>Places We Make The Rounds</h1>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="featured-news-item" onClick={() => handleRedirect('https://techcabal.com/')}>
              <img src={techcabal} alt="TechCabal" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="featured-news-item" onClick={() => handleRedirect('https://techcrunch.com/')}>
              <img src={techcrunch} alt="TechCrunch" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="featured-news-item" onClick={() => handleRedirect('https://technext.ng/')}>
              <img src={technext} alt="TechNext" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="featured-news-item" onClick={() => handleRedirect('https://nairametrics.com/')}>
              <img src={nairametrics} alt="Nairametrics" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedSection;
