import React from 'react';
import { useNavigate } from 'react-router-dom';

const LearnSection = () => {
    const navigate = useNavigate();

    const handleOptionClick = (redirectUrl) => {
        if (redirectUrl) {
            navigate(redirectUrl);
        }
    };

    return (
        <section className='section-learn'>
            <div className="container">
                <div className="learn-content">
                    <div className="learn-left">
                        <img src="https://media.istockphoto.com/id/1815150556/photo/training-word-on-speech-bubble.jpg?s=612x612&w=0&k=20&c=aOhtwp4yj45eW4hVGYI7TAxY62KNj5MY59F48aw6Jog=" alt="Learn" />
                        <div>
                            <h2>What do you want to learn?</h2>
                            <p>Choose from a variety of courses to enhance your skills and knowledge.</p>
                        </div>
                    </div>
                    <div className="learn-right">
                        <h3>Find your area of interest:</h3>
                        <select onChange={(e) => handleOptionClick(e.target.value)}>
                            <option value="">Find Programme</option>
                            <option value="/Legal-and-compliance">Legal & Compliance</option>
                            <option value="/Corporate-and-banking-sector">Corporate & Finance</option>
                            <option value="/Human-resource-and-product-development-studies">Human Resource Management</option>
                            <option value="/Product-Management">Products Management</option>
                            <option value="/Pre-degree">Pre-Degree Programme</option>
                            <option value="/Pay-per-course">Pay pay course</option>
                        </select>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LearnSection;
