import React from 'react';
import Buildlearn from '../assets/images/online.jpg';
import { Link } from 'react-router-dom';



const PayPerCourse = () => {
  return (
    <section className="paypercourse-section">
      <div className="paypercourse-container">
        <div className="per-course-left">
          <h2>Explore Pay-Per-Course Options</h2>
          <p>What's your preference ? 
            <br />
            Discover a variety of courses that suit your 
            learning needs and career goals. 
            <br />
            <br />
            Choose from a wide range of topics and start 
            learning today from various industries and sectors</p>
          <div className="per-course-grid">
            <div className="per-course-item">Tech</div>
            <div className="per-course-item">Legal</div>
            <div className="per-course-item">Compliance</div>
            <div className="per-course-item">Business Development</div>

            <Link to="/Pay-per-course" className="btn btn-primary" style={{ textDecoration: 'none' }}>
          Learn More
        </Link>
          </div>
        </div>
        <div className="per-course-right">
          <img src={Buildlearn} alt="Course pay per course" />
          <h3>Enhance Your Skills</h3>
          <p>Join our courses to gain new skills and knowledge that will help you excel in your career. Our expert instructors and comprehensive curriculum ensure you get the best learning experience.</p>
        </div>
      </div>
    </section>
  );
};

export default PayPerCourse;
