import React from 'react';
import Buildlearn from '../assets/images/online.jpg';
import { Link } from 'react-router-dom';



const PayPerCourse = () => {
  return (
    <section className="paypercourse-section">
      <div className="paypercourse-container">
        <div className="per-course-left">
          <h2>What you want, <br />your goals, your plan</h2>
          <p>
           Any course, any field of preferred study. Right here on Lecturemeet gives you complete accessibilty</p>
          <div className="per-course-grid">
            <div className="per-course-item"> Business Development and Risk Compliance</div>
            <div className="per-course-item">Anti Money Laundering (AML/CFT) Compliance</div>
            <div className="per-course-item">Human Resource Management</div>
            <div className="per-course-item">Corporate Law Governance, and Regulatory Policies (CLG)</div>
            <div className="per-course-item">Insurance Law & Policy (Regulatory framework - Nigeria)</div>
            <div className="per-course-item">Intellectual Property Law</div>
            <div className="per-course-item">Mergers and Acquisition Manager</div>
            <div className="per-course-item">Product Marketing Guide</div>

            <Link to="/Pay-per-course" className="btn btn-primary" style={{ textDecoration: 'none' }}>
          Learn More
        </Link>
          </div>
        </div>
        <div className="per-course-right">
          <img src={Buildlearn} alt="Course pay per course" />
          <div className="per-course-grid">
            
            <div className="per-course-item">Taxation Policy & Regulatory Compliance</div>
            <div className="per-course-item">Compliance in Payment Card Industry Data Security Standard (PCI DSS)</div>
            <div className="per-course-item">Banking and Financial Services Law</div>
            <div className="per-course-item">Product Management</div>
        </div>
        </div>
      </div>
    </section>
  );
};

export default PayPerCourse;
