import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import './assets/css/header.css';
import './assets/css/footer.css';
import './assets/css/body.css';
import './assets/css/Chatbox.css';
import './assets/css/general.css';
import './assets/css/Main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/Footer';
import NavHeader from './components/NavHeader';
import ChatBox from './components/ChatBox';
import Routes from './Route';

function App() {
  return (
    <Router>
      <div className="App">
        <NavHeader />

        <Routes />
        
<ChatBox />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
